import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import LoadingBackdrop from "./LoadingBackdrop";

const ModalContainer = ({
  children,
  loading,
  title,
}: {
  children: ReactNode;
  loading: boolean;
  title?: string;
}) => {
  return (
    <Box>
      {!loading && (
        <Box>
          {title && (
            <Typography
              variant="headlineLarge"
              color="onSurface.main"
              sx={{ mb: 2 }}
              gutterBottom
            >
              {title}
            </Typography>
          )}
          {children}
        </Box>
      )}
      {loading && (
        <Box padding={2}>
          <LoadingBackdrop />
        </Box>
      )}
    </Box>
  );
};

export default ModalContainer;
