import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import React, { createContext, ReactNode, useContext } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { ApplicationScope } from "../types/App";
import { DEFAULT_ERROR_MESSAGE, ErrorCodeMap } from "../types/Error";
import { ErrorCause } from "./ApiProvider";
import { useAuth } from "./AuthProvider";

export const LONG_STALE_TIME = 24 * 60 * 60 * 1000; // 24 hours

export type APQueryOptions = {
  appTypeScope?: ApplicationScope;
  disabled?: boolean;
  mutationOptions?: {
    successNavPath?: string;
    successAlertMessage?: string;
    errorAlertMessage?: string;
    refreshOnSuccess?: boolean;
  };
  keepPreviousData?: boolean;
};

// Define the context with a fetch function
const APQueryContext = createContext<{
  isQueryEnabled: (options: APQueryOptions) => boolean;
  defaultOnMutateError: (options?: APQueryOptions) => (error?: Error) => void;
  defaultOnMutateSuccess: (options: APQueryOptions) => (() => void) | undefined;
  defaultOnMutateSettled: (invalidateList: unknown[][]) => void | undefined;
  optimisiticUpdateOnMutate: (queryKey: unknown[], data: unknown) => void;
  getDataByQueryKey: (queryKey: unknown[]) => unknown;
  alertOnLongRequest: <T>(
    request: Promise<T>,
    timeToAlert: number,
    alertMessage: string,
    timeToError?: number,
    errorMessage?: string
  ) => Promise<T>;
}>({
  isQueryEnabled: () => false,
  defaultOnMutateError: () => () => {},
  defaultOnMutateSuccess: () => undefined,
  defaultOnMutateSettled: () => undefined,
  optimisiticUpdateOnMutate: () => {},
  getDataByQueryKey: () => {},
  alertOnLongRequest: () => Promise.reject("Provider not configured"),
});

// Custom hook to use the Auth context
export const useAPQuery = () => {
  return useContext(APQueryContext);
};

// Auth Provider component
export const APQueryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { inAppScope } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isQueryEnabled = (options: APQueryOptions) => {
    return (
      !options.disabled &&
      (!options.appTypeScope || !!inAppScope(options.appTypeScope))
    );
  };

  const defaultOnMutateError = (options?: APQueryOptions) => {
    const errorMessage =
      (options && options.mutationOptions?.errorAlertMessage) || null;

    return (error?: Error) => {
      const errorCode = error && (error.cause as ErrorCause)?.errorCode;
      const message =
        errorMessage ||
        (errorCode && ErrorCodeMap[errorCode]) ||
        DEFAULT_ERROR_MESSAGE;

      enqueueSnackbar(message, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    };
  };

  const defaultOnMutateSuccess = (options: APQueryOptions) => {
    const successNavPath = options.mutationOptions?.successNavPath || null;
    const refreshOnSuccess = options.mutationOptions?.refreshOnSuccess || false;
    const message = options.mutationOptions?.successAlertMessage || null;

    return successNavPath || message
      ? () => {
          if (message) {
            enqueueSnackbar(message, {
              variant: "success",
              action: (key) => (
                <IconButton onClick={() => closeSnackbar(key)} color="inherit">
                  <CloseIcon />
                </IconButton>
              ),
            });
          }

          if (successNavPath) {
            navigate(successNavPath);
          } else if (refreshOnSuccess) {
            navigate(0);
          }
        }
      : undefined;
  };

  const defaultOnMutateSettled = (invalidateList: unknown[][]) => {
    return invalidateList
      ? invalidateList.forEach((queryKey) => {
          queryClient.invalidateQueries(queryKey);
        })
      : undefined;
  };

  const optimisiticUpdateOnMutate = async (
    queryKey: unknown[],
    data: unknown
  ) => {
    // // Cancel any outgoing refetches
    // // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });

    // Optimistically update to the new value
    queryClient.setQueryData(queryKey, data);
  };

  const getDataByQueryKey = (queryKey: unknown[]) => {
    return queryClient.getQueryData(queryKey);
  };

  const alertOnLongRequest = <T,>(
    request: Promise<T>,
    timeToAlert: number,
    alertMessage: string,
    timeToError?: number,
    errorMessage?: string
  ) => {
    const timeout = setTimeout(() => {
      enqueueSnackbar(alertMessage, {
        variant: "info",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }, timeToAlert); // Show message if request takes more than 3 seconds

    const requestPromise = request.finally(() => clearTimeout(timeout));
    const promises = [requestPromise];
    if (timeToError && errorMessage) {
      const timeoutPromise: Promise<T> = new Promise((_, reject) => {
        setTimeout(() => {
          reject(new Error(errorMessage));
        }, timeToError);
      });
      promises.push(timeoutPromise);
    }

    return Promise.race(promises);
  };

  return (
    <APQueryContext.Provider
      value={{
        isQueryEnabled,
        defaultOnMutateError,
        defaultOnMutateSuccess,
        defaultOnMutateSettled,
        optimisiticUpdateOnMutate,
        getDataByQueryKey,
        alertOnLongRequest,
      }}
    >
      {children}
    </APQueryContext.Provider>
  );
};
