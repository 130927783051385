import { AutoCompleteOption } from "../components/form/APAutoComplete";
import { ToggleButtonOption } from "../components/form/APFormToggleGroup";
import { State } from "./State";
import { RedbookVehiclePhoto } from "./Vehicles";

export type DossierQuoteSyncResult =
  | "NO_ACTIVE_DOSSIER"
  | "ON_COOLDOWN"
  | "CATCHE_DRIVER_NOT_LINKED"
  | "SUCCESS";

export type DossierQuoteSyncStatus = "SYNCING" | "NOT_SYNCING";

export type Quote = {
  crmQuoteId: string;
  catchEQuoteId: string;
  catchEQuoteUrl: string;
  crmAccountId: string | null;
  crmContactId: string;
  vehicleRedbookKey: string;
  name: string;
  description: string | null;
  isLocked: boolean;
  isVisible: boolean;
  budgetedAnnualKm: number;
  budgetedAnnualKmFormatted: string;
  purchaseType: VehiclePurchaseType;
  businessUsage: number;
  businessUsageFormatted: string;
  annualGrossSalary: number;
  annualGrossSalaryFormatted: string;
  financeBrokerageRevenue: number;
  financeBrokerageRevenueFormatted: string;
  vehicleSourcingRevenue: number;
  vehicleSourcingRevenueFormatted: string;
  productRevenue: number;
  productRevenueFormatted: string;
  totalRevenue: number;
  totalRevenueFormatted: string;
  vehicleOnroadCosts: number;
  vehicleOnroadCostsFormatted: string;
  totalAmountFinanced: number;
  totalAmountFinancedFormatted: string;
  savings: number;
  savingsFormatted: string;
  weeklyNetCosts: number;
  weeklyNetCostsFormatted: string;
  startDate: string | null;
  endDate: string | null;
  termMonths: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleVariant: string;
  vehicleState: number;
  vehicleStateFormatted: string;
  vehicleImage: string | null;
  updatedAt: string;
};

export const payCyclePeriodOptions: ToggleButtonOption[] = [
  { label: "Weekly", id: "weekly" },
  { label: "Fortnight", id: "fortnight" },
  { label: "Monthly", id: "monthly" },
  { label: "Annual", id: "annual" },
];

export type QuotePayPeriod = "weekly" | "fortnight" | "monthly" | "annual";
export type VehiclePurchaseType =
  | "new"
  | "renovation"
  | "refinance"
  | "sale-and-leaseback"
  | "used";

export const vehiclePurchaseTypeOptions: AutoCompleteOption[] = [
  { label: "New", id: "new" },
  { label: "Used", id: "used" },
  { label: "Lease my own car", id: "sale-and-leaseback" },
];

export type QuoteInclusions = {
  finance: number;
  servicing: number;
  tyres: number;
  fuel: number;
  rego: number;
  insurance: number;
  managementFees: number;
  luxuryCharge: number;
  gst: number;
};

export type QuotePeriodValues = {
  inclusions: QuoteInclusions;
  packageValue: number;
  netCost: number;
};

export type CalculatedQuote = {
  id: string;
  dossierId: string;
  name: string;
  isLocked: boolean;
  vehicle: {
    make: string;
    makeId: string;
    model: string;
    modelId: string;
    year: string;
    description: string;
    variantId: string;
    variantCode: string;
    series: string;
    body: string;
    seats: number;
    transmission: string;
    driveType: string;
    variantShortName: string;
    variantLongName: string;
    fuelType: string;
    co2Emissions: number;
    ancapRating: number;
    images: RedbookVehiclePhoto[];
    standardEquipment: string[];
    initialOdometer: number | null;
  };
  state: State;
  primaryImageCode: string;
  valuesPerPeriod: {
    weekly: QuotePeriodValues;
    fortnight: QuotePeriodValues;
    monthly: QuotePeriodValues;
    annual: QuotePeriodValues;
  };
  payPeriod: QuotePayPeriod;
  runningCostsPA: number;
  annualSalary: number;
  annualKm: number;
  vehicleOnroadCosts: number;
  vehicleResidualGross: number;
  term: number;
  businessUsage: number;
  savings: number;
  savingsPerYear: number;
  lifeOfLeaseSavings: number;
  listPrice: number;
  catchEEquipmentIds: string[];
  catchEQuoteId: string;
  catchEQuoteUrl: string;
  purchaseType: VehiclePurchaseType;
  discount: number | null;
};
