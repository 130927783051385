import { useMutation } from "react-query";

import { DeepPartial } from "../../helpers/typescript";
import { AssetAndLiability, FinanceApplication } from "../../types/Dossier";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type FinanceApplicationFileUploads = {
  driversLicenseFrontImage?: File;
  driversLicenseBackImage?: File;
  lastTaxReturnImage?: File;
  prevTaxReturnImage?: File;
  latestPayslipImage?: File;
  prevPayslipImage?: File;
  spouseLastTaxReturnImage?: File;
  spousePrevTaxReturnImage?: File;
  spouseLatestPayslipImage?: File;
  spouseIncomeDeclarationImage?: File;
};

export type AssetsAndLiabilitiesUpdatePayload = {
  add?: Omit<AssetAndLiability, "id" | "financeId" | "name" | "typeLabel">[];
  remove?: string[];
  update?: (Pick<AssetAndLiability, "id"> &
    Partial<
      Omit<AssetAndLiability, "id" | "financeId" | "name" | "typeLabel">
    >)[];
};

export type UpdateFinanceApplicationPayload = DeepPartial<
  Omit<FinanceApplication, "assetsAndLiabilities">
> & {
  fileUploads?: FinanceApplicationFileUploads;
  assetsAndLiabilities?: AssetsAndLiabilitiesUpdatePayload;
};

function buildImagesPayload(payload: FinanceApplicationFileUploads): FormData {
  const formData = new FormData();

  for (const key in payload) {
    const value = payload[key as keyof FinanceApplicationFileUploads];
    if (value) {
      formData.append(key, value);
    }
  }

  return formData;
}

export const updateFinanceApplication = (
  dossierId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { defaultOnMutateError, defaultOnMutateSuccess } = useAPQuery();

  return useMutation({
    mutationFn: async (
      payload: UpdateFinanceApplicationPayload
    ): Promise<FinanceApplication | null> => {
      const { fileUploads, ...financeApplication } = payload;

      if (fileUploads) {
        const imagesFormData = buildImagesPayload(fileUploads);

        await fetchWithAuth<null>("updateFinanceApplicationImages", dossierId, {
          requestOptions: {
            method: "PATCH",
            body: imagesFormData,
            excludeContentTypeHeader: true,
          },
        });
      }

      if (Object.keys(financeApplication).length > 0) {
        return fetchWithAuth<FinanceApplication>(
          "updateFinanceApplication",
          dossierId,
          {
            requestOptions: {
              method: "PATCH",
              body: JSON.stringify(financeApplication),
            },
          }
        );
      } else {
        return null;
      }
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
