import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const qualifyNewLead = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (): Promise<string> => {
      return fetchWithAuth<string>("qualifyNewLead", undefined, {
        requestOptions: { method: "post" },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled([["dossiers"]]),
  });
};
