import { useMutation } from "react-query";

import { DossierQuoteSyncResult } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type SyncQuotesPayload = {
  contactId: string;
};

export const syncQuotes = (quoteId?: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (
      payload: SyncQuotesPayload
    ): Promise<DossierQuoteSyncResult> => {
      return fetchWithAuth<DossierQuoteSyncResult>(
        "syncQuotes",
        payload.contactId,
        {
          requestOptions: { method: "post" },
        }
      );
    },
    onError: defaultOnMutateError(),
    onSuccess: (result: DossierQuoteSyncResult) => {
      let errorMessage: string | undefined;

      switch (result) {
        case "ON_COOLDOWN":
          errorMessage = "Sync is on cooldown, try again soon.";
          break;
        case "NO_ACTIVE_DOSSIER":
          errorMessage = "This contact has no active dossier.";
          break;
        case "CATCHE_DRIVER_NOT_LINKED":
          errorMessage = "This contact is not linked to a Catch-e driver";
          break;
      }

      const success = defaultOnMutateSuccess(options);
      const onError = defaultOnMutateError({
        ...options,
        mutationOptions: {
          ...options.mutationOptions,
          errorAlertMessage: errorMessage,
        },
      });

      if (result === "SUCCESS") {
        success && success();

        const invalidateList = [["quotes"], ["dossier"]];

        if (quoteId) {
          invalidateList.push(["quote", quoteId]);
        }

        defaultOnMutateSettled(invalidateList);
      } else {
        onError();
      }
    },
  });
};
