import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

type QualifyLeadPayload = {
  mobilePhone: string;
  companyName: string;
  leadSourceCode?: string;
};

export const qualifyLead = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
    alertOnLongRequest,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: QualifyLeadPayload): Promise<string> => {
      return alertOnLongRequest(
        fetchWithAuth<string>("qualifyLead", undefined, {
          requestOptions: { method: "post", body: JSON.stringify(payload) },
        }),
        10000,
        "Working on the car of your dreams, Almost there.",
        30000,
        "Error: lead qualification took too long"
      );
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: () => defaultOnMutateSettled([["user"]]),
  });
};
