import { alpha, createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    displayLarge: React.CSSProperties;
    displayMedium: React.CSSProperties;
    displaySmall: React.CSSProperties;
    headlineLarge: React.CSSProperties;
    headlineMedium: React.CSSProperties;
    headlineSmall: React.CSSProperties;
    titleLarge: React.CSSProperties;
    titleMedium: React.CSSProperties;
    titleSmall: React.CSSProperties;
    bodyLarge: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    bodySmall: React.CSSProperties;
    labelLarge: React.CSSProperties;
    labelMedium: React.CSSProperties;
    labelSmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    displayLarge?: React.CSSProperties;
    displayMedium?: React.CSSProperties;
    displaySmall?: React.CSSProperties;
    headlineLarge?: React.CSSProperties;
    headlineMedium?: React.CSSProperties;
    headlineSmall?: React.CSSProperties;
    titleLarge?: React.CSSProperties;
    titleMedium?: React.CSSProperties;
    titleSmall?: React.CSSProperties;
    bodyLarge?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    bodySmall?: React.CSSProperties;
    labelLarge?: React.CSSProperties;
    labelMedium?: React.CSSProperties;
    labelSmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    displayLarge: true;
    displayMedium: true;
    displaySmall: true;
    headlineLarge: true;
    headlineMedium: true;
    headlineSmall: true;
    titleLarge: true;
    titleMedium: true;
    titleSmall: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    onPrimary: Palette["primary"];
    tertiaryGreen: Palette["primary"];
    tertiaryBlue: Palette["primary"];
    tertiaryDarkBlue: Palette["primary"];
    tertiaryYellow: Palette["primary"];
    neutralWhite: Palette["primary"];
    neutralGray: Palette["primary"];
    surface: Palette["primary"];
    surfaceVariant: Palette["primary"];
    surfaceContainer: Palette["primary"];
    onSurface: Palette["primary"];
    onSurfaceVariant: Palette["primary"];
    outline: Palette["primary"];
    outlineVariant: Palette["primary"];
  }
  interface PaletteOptions {
    onPrimary: PaletteOptions["primary"];
    tertiaryGreen: PaletteOptions["primary"];
    tertiaryBlue: PaletteOptions["primary"];
    tertiaryDarkBlue: PaletteOptions["primary"];
    tertiaryYellow: PaletteOptions["primary"];
    neutralWhite: PaletteOptions["primary"];
    neutralGray: PaletteOptions["primary"];
    surface: PaletteOptions["primary"];
    surfaceVariant: PaletteOptions["primary"];
    surfaceContainer: PaletteOptions["primary"];
    onSurface: PaletteOptions["primary"];
    onSurfaceVariant: PaletteOptions["primary"];
    outline: PaletteOptions["primary"];
    outlineVariant: PaletteOptions["primary"];
  }
}

declare module "@mui/material/ToggleButtonGroup" {
  interface ToggleButtonGroupPropsColorOverrides {
    outline: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    onSurface: true;
  }
}

export default createTheme({
  palette: {
    primary: {
      main: "#00ABD0",
      contrastText: "#ffffff",
      light: alpha("#00ABD0", 0.2),
    },
    onPrimary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#EB008B",
      contrastText: "#ffffff",
    },
    tertiaryGreen: {
      main: "#00D08D",
      light: alpha("#00D08D", 0.2),
    },
    tertiaryBlue: {
      main: "#0043D0",
      light: alpha("#0043D0", 0.2),
    },
    tertiaryDarkBlue: {
      main: "#003E59",
      light: alpha("#003E59", 0.2),
    },
    tertiaryYellow: {
      main: "#FFBF00",
      light: alpha("#FFBF00", 0.2),
    },
    neutralGray: {
      main: "#F2F3F4",
    },
    neutralWhite: {
      main: "#FFFFFF",
    },
    error: {
      main: "#B3261E",
    },
    surface: {
      dark: "#DED8E1",
      main: "#FEF7FF",
    },
    surfaceVariant: {
      main: "#E7E0EC",
      light: alpha("#E7E0EC", 0.6),
    },
    surfaceContainer: {
      dark: "#F7F2FA",
      main: "#F3EDF7",
      light: "#FFFFFF",
    },
    onSurface: {
      main: "#1D1B20",
    },
    onSurfaceVariant: {
      main: "#49454F",
    },
    outline: {
      main: "#79747E",
    },
    outlineVariant: {
      main: "#CAC4D0",
    },
    background: {
      default: "#F7F2FA",
    },
  },
  typography: {
    fontFamily: ["Manrope", "Roboto"].join(","),
    displayLarge: {
      fontWeight: 400,
      fontSize: "3.563rem",
    },
    displayMedium: {
      fontWeight: 400,
      fontSize: "2.813rem",
    },
    displaySmall: {
      fontWeight: 400,
      fontSize: "2.25rem",
    },
    headlineLarge: {
      fontWeight: 400,
      fontSize: "2rem",
    },
    headlineMedium: {
      fontWeight: 400,
      fontSize: "1.75rem",
    },
    headlineSmall: {
      fontWeight: 400,
      fontSize: "1.5rem",
    },
    titleLarge: {
      fontWeight: 400,
      fontSize: "1.375rem",
    },
    titleMedium: {
      fontWeight: 500,
      fontSize: "1rem",
    },
    titleSmall: {
      fontWeight: 500,
      fontSize: "0.875rem",
    },
    bodyLarge: {
      fontWeight: 400,
      fontSize: "1rem",
    },
    bodyMedium: {
      fontWeight: 400,
      fontSize: "0.875rem",
    },
    bodySmall: {
      fontWeight: 400,
      fontSize: "0.75rem",
    },
    labelLarge: {
      fontWeight: 500,
      fontSize: "0.875rem",
    },
    labelMedium: {
      fontWeight: 500,
      fontSize: "0.75rem",
    },
    labelSmall: {
      fontWeight: 500,
      fontSize: "0.688rem",
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.surfaceVariant.main,
          ":hover": {
            backgroundColor: theme.palette.surfaceVariant.light,
          },
          ":focus": {
            backgroundColor: theme.palette.surfaceVariant.light,
          },
          ":focus-within": {
            backgroundColor: theme.palette.surfaceVariant.light,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.onSurfaceVariant.main,
          fontWeight: theme.typography.titleMedium.fontWeight,
          fontSize: theme.typography.titleMedium.fontSize,
        }),
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.outline.main,
          color: theme.palette.onSurface.main,
          fontWeight: theme.typography.labelLarge.fontWeight,
          fontSize: theme.typography.labelLarge.fontSize,
          textTransform: "capitalize",
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: theme.typography.labelLarge.fontWeight,
          fontSize: theme.typography.labelLarge.fontSize,
          textTransform: "capitalize",
          borderRadius: "6.25rem",
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          displayLarge: "h1",
          displayMedium: "h2",
          displaySmall: "h3",
          headlineLarge: "h2",
          headlineMedium: "h3",
          headlineSmall: "h4",
          titleLarge: "h3",
          titleMedium: "h4",
          titleSmall: "div",
          bodyLarge: "p",
          bodyMedium: "p",
          bodySmall: "p",
          labelLarge: "span",
          labelMedium: "span",
          labelSmall: "span",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.outlineVariant.main,
          borderRadius: "0.75rem",
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.surfaceContainer.light,
          [theme.breakpoints.up("md")]: {
            marginBottom: "2rem",
          },
        }),
      },
    },
  },
});
