import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, Divider, List, ListItem, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getContact } from "../../api/user/getContact";
import { getKeycloakUserInfo } from "../../api/user/getKeycloakUserInfo";
import { setTemporaryPassword } from "../../api/user/setTemporaryPassword";
import { syncQuotes } from "../../api/user/syncQuotes";
import APAccordion from "../../components/APAccordion";
import APCheckList from "../../components/APChecklist";
import BackButton from "../../components/buttons/BackButton";
import CatchELoadingButton from "../../components/buttons/CatchELoadingButton";
import RefreshButton from "../../components/buttons/RefreshButton";
import PageContainer from "../../components/PageContainer";
import { formatDate } from "../../helpers/dates";

const ViewKeycloakUser = () => {
  const { contactId } = useParams();
  const navigate = useNavigate();
  const [tempPassword, setTempPassword] = useState(null as string | null);

  if (!contactId) {
    // TODO: improve client side error messaging
    throw new Error("contact id param is misisng");
  }

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } =
    setTemporaryPassword(contactId, {
      mutationOptions: {
        successAlertMessage: "Temporary password has been set for this user.",
      },
    });

  const { mutateAsync: mutateSyncQuotesAsync, isLoading: isSyncingQuotes } =
    syncQuotes(undefined, {
      mutationOptions: {
        successAlertMessage: "Quotes have synced successfully.",
      },
    });

  const contact = getContact(contactId);
  const keycloakUser = getKeycloakUserInfo(contactId);

  if (contact.isError) {
    throw contact.error;
  }

  if (keycloakUser.isError) {
    throw keycloakUser.error;
  }

  const onRefresh = async () => {
    navigate(0);
  };

  const onReset = async () => {
    const password = await mutateAsyncUpdate();
    setTempPassword(password);
  };

  const onQuotesSync = async () => {
    mutateSyncQuotesAsync({ contactId });
  };

  const federatedIdentities = keycloakUser.data?.user?.federatedIdentities;
  const googleUsername = federatedIdentities?.google || null;
  const microsoftUsername = federatedIdentities?.microsoft || null;
  const linkedinUsername = federatedIdentities?.linkedin || null;
  const hasFederatedIdentity =
    googleUsername || microsoftUsername || linkedinUsername;

  const isLoading =
    contact.isFetching || keycloakUser.isFetching || isLoadingUpdate;
  const isSuccess = contact.isSuccess && keycloakUser.isSuccess;

  return (
    <PageContainer loading={isLoading} title="User">
      {isSuccess && (
        <Stack spacing={2}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="titleSmall" color="onSurface.main">
                {contact.data.name} - {contact.data.parentAccountEmployerName}
              </Typography>
              <Box>
                <CatchELoadingButton
                  isLoading={isSyncingQuotes}
                  onClick={onQuotesSync}
                  disabled={!contact.data.catchEDriverId}
                />
                <RefreshButton onClick={onRefresh} />
              </Box>
            </Box>
            <Box>
              <APCheckList
                items={[
                  {
                    label: "Keycloak User Setup",
                    checked: !!keycloakUser.data.user,
                  },
                  {
                    label: "User Enabled",
                    checked: !!keycloakUser.data.user?.enabled,
                  },
                  {
                    label: "Email Verified",
                    checked: !!keycloakUser.data.user?.emailVerified,
                  },
                  {
                    label: "Catch-e Driver Linked to Contact",
                    checked: !!contact.data.catchEDriverId,
                  },
                  {
                    label: `User Login Success${
                      keycloakUser.data.user?.initialLoginDate
                        ? ` - ${formatDate(
                            keycloakUser.data.user.initialLoginDate,
                            "h:mm a DD/MM/YYYY"
                          )}`
                        : ""
                    }`,
                    checked: !!keycloakUser.data.user?.initialLoginDate,
                  },
                ]}
              />
            </Box>
          </Box>
          <Box>
            <APAccordion title="How to Login?" defaultExpanded>
              <Box my={1}>
                <Typography variant="titleMedium">
                  Single Sign On (SSO)
                </Typography>
                <Box mt={2}>
                  <Typography variant="bodySmall">SSO Links</Typography>
                  <Box>
                    <APCheckList
                      items={[
                        {
                          label: `Google${
                            googleUsername ? ` - ${googleUsername}` : ""
                          }`,
                          checked: !!googleUsername,
                        },
                        {
                          label: `Microsoft${
                            microsoftUsername ? ` - ${microsoftUsername}` : ""
                          }`,
                          checked: !!microsoftUsername,
                        },
                        {
                          label: `Linkedin${
                            linkedinUsername ? ` - ${linkedinUsername}` : ""
                          }`,
                          checked: !!linkedinUsername,
                        },
                      ]}
                      hideIconOnUnchecked
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography variant="bodySmall">
                    Emails to use to SSO register
                  </Typography>
                  <List sx={{ py: 0 }}>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        primary={`• ${contact.data.email}`}
                        primaryTypographyProps={{ variant: "bodySmall" }}
                      ></ListItemText>
                    </ListItem>
                    {contact.data.email2 && (
                      <ListItem sx={{ py: 0 }}>
                        <ListItemText
                          primary={`• ${contact.data.email2}`}
                          primaryTypographyProps={{ variant: "bodySmall" }}
                        ></ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Box>
              </Box>
              {!hasFederatedIdentity && (
                <>
                  <Divider sx={{ borderColor: "onSurface.main", my: 2 }} />
                  <Box>
                    <Typography variant="titleMedium">OR</Typography>
                    <Typography variant="titleMedium">
                      Username and Password
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="bodySmall">
                        Username: {keycloakUser.data.user?.username}
                      </Typography>
                      {tempPassword && (
                        <Typography variant="bodySmall" sx={{ my: 1 }}>
                          Temporary Password: <b>{tempPassword}</b>
                        </Typography>
                      )}
                      <Box mt={2}>
                        <Button
                          size="large"
                          color="primary"
                          variant="outlined"
                          disableElevation={true}
                          startIcon={<MailOutlineIcon />}
                          onClick={onReset}
                        >
                          Reset Password
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </APAccordion>
          </Box>
          <Box sx={{ pt: 2 }}>
              <BackButton />
            </Box>
        </Stack>
      )}
    </PageContainer>
  );
};

export default ViewKeycloakUser;
