import { Location } from "react-router-dom";

export const isAbsoluteUrl = (url: string): boolean => {
  const regex = /^[a-zA-Z][a-zA-Z\d+\-.]*:/;
  return regex.test(url);
};

export const isModalRoute = (location: Location<unknown>): boolean => {
  return location.search.includes("modal=");
};

export const removeModalParam = (params: URLSearchParams): URLSearchParams => {
  params.delete("modal");

  return params;
};

export const replaceModalParam = (
  newModal: string,
  params: URLSearchParams
): URLSearchParams => {
  params.set("modal", newModal);

  return params;
};

export const addModalToHref = (newModal: string, href: string) => {
  const url = new URL(href);
  const params = new URLSearchParams(url.search);
  const newParams = replaceModalParam(newModal, params);
  url.search = newParams.toString();

  return url.toString();
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
