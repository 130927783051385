import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import StarFilledIcon from "@mui/icons-material/StarOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutlineOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SubmitHandler, useForm } from "react-hook-form";

import APAccordion from "../../components/APAccordion";
import APDetailRow from "../../components/APDetailRow";
import CatchEButton from "../../components/buttons/CatchEButton";
import CatchELoadingButton from "../../components/buttons/CatchELoadingButton";
import FaqLink from "../../components/FaqLink";
import APToggleGroup from "../../components/form/APFormToggleGroup";
import APTextField from "../../components/form/APTextField";
import ImageCarousel from "../../components/ImageCarousel";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import TermsButton from "../../components/TermsButton";
import FaqCarousel from "../../faq/components/FaqCarousel";
import { toDollars, toFormattedNumber } from "../../helpers/currencies";
import { appendRedbookImageParams } from "../../helpers/files";
import { getPurchaseTypeLabel } from "../../helpers/quotes";
import { Faq, FaqAppLocation } from "../../types/Faq";
import {
  payCyclePeriodOptions,
  QuotePayPeriod,
  QuotePeriodValues,
  VehiclePurchaseType,
} from "../../types/Quote";
import { ViewQuoteSubmitAction } from "../viewQuote";
type Inputs = {
  quoteName: string;
  payCyclePeriod: QuotePayPeriod;
};

interface CalculatedQuoteProps {
  id: string;
  name: string;
  isLocked: boolean;
  isPrimary: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleVariantDescription: string;
  vehicleImages: string[];
  valuesPerPeriod: {
    weekly: QuotePeriodValues;
    fortnight: QuotePeriodValues;
    monthly: QuotePeriodValues;
    annual: QuotePeriodValues;
  };
  payPeriod: QuotePayPeriod;
  runningCostsPA: number;
  annualSalary: number;
  annualKm: number;
  purchaseType: VehiclePurchaseType;
  vehicleOnroadCosts: number;
  vehicleResidualGross: number;
  term: number;
  businessUsage: number;
  savings: number;
  savingsPerYear: number;
  lifeOfLeaseSavings: number;
  catchEQuoteUrl: string;
  canCreateQuote: boolean;
  isSubmitting: boolean;
  isSyncingQuotes: boolean;
  handleSubmit: (
    name: string,
    payPeriod: QuotePayPeriod,
    submitAction: ViewQuoteSubmitAction
  ) => Promise<void>;
  handleSetPrimary: (quoteId: string) => void;
  handleSyncQuotes: () => void;
  disablePrimaryToggle: boolean;
  faqAppLocations: FaqAppLocation[];
  carouselAppLocationId: string | null;
  carouselFaqs: Faq[];
  handleCarouselFaqClick: (faqId: string) => void;
}

const peroidLabelMap: Record<QuotePayPeriod, string> = {
  weekly: "week",
  fortnight: "fortnight",
  monthly: "month",
  annual: "year",
};

const CalculatedQuote = ({
  id,
  name,
  isLocked,
  isPrimary,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  vehicleVariantDescription,
  vehicleImages,
  payPeriod,
  valuesPerPeriod,
  runningCostsPA,
  annualSalary,
  annualKm,
  purchaseType,
  vehicleOnroadCosts,
  vehicleResidualGross,
  term,
  businessUsage,
  savings,
  savingsPerYear,
  lifeOfLeaseSavings,
  catchEQuoteUrl,
  canCreateQuote,
  isSubmitting,
  isSyncingQuotes,
  handleSubmit,
  handleSetPrimary,
  handleSyncQuotes,
  disablePrimaryToggle,
  faqAppLocations,
  carouselAppLocationId,
  carouselFaqs,
  handleCarouselFaqClick,
}: CalculatedQuoteProps) => {
  const {
    control,
    watch,
    handleSubmit: _handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });
  const payCyclePeriod = watch("payCyclePeriod") || payPeriod;
  const peroidValues = valuesPerPeriod[payCyclePeriod];

  const onEdit: SubmitHandler<Inputs> = async (data) => {
    handleSubmit(data.quoteName, data.payCyclePeriod, "edit");
  };

  const onNew: SubmitHandler<Inputs> = async (data) => {
    handleSubmit(data.quoteName, data.payCyclePeriod, "new");
  };

  const onUser: SubmitHandler<Inputs> = async (data) => {
    handleSubmit(data.quoteName, data.payCyclePeriod, "user");
  };

  const onDownload: SubmitHandler<Inputs> = async (data) => {
    handleSubmit(data.quoteName, data.payCyclePeriod, "download");
  };

  const onNext: SubmitHandler<Inputs> = async (data) => {
    handleSubmit(data.quoteName, data.payCyclePeriod, "next");
  };

  const isError = Object.keys(errors).length > 0;

  return (
    <Box>
      <Stack spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!isLocked && (
            <IconButton
              sx={{ mr: 1 }}
              size="large"
              aria-label="edit-quote"
              color="primary"
              onClick={_handleSubmit(onEdit)}
            >
              <EditOutlinedIcon />
            </IconButton>
          )}
          <Box sx={{ flex: 1 }}>
            <Typography variant="titleMedium">
              {vehicleYear} {vehicleMake} {vehicleModel}{" "}
              {vehicleVariantDescription}
            </Typography>
          </Box>
          <Box sx={{ alignSelf: "end" }}>
            {isPrimary ? (
              <StarFilledIcon sx={{ m: 1.5 }} color="secondary" />
            ) : (
              <IconButton
                size="large"
                aria-label="favourite-quote"
                color="onSurface"
                onClick={() => handleSetPrimary(id)}
                disabled={disablePrimaryToggle}
              >
                <StarOutlineIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {vehicleImages.length > 0 && (
          <Box>
            <ImageCarousel
              images={vehicleImages.map((image) =>
                appendRedbookImageParams(image)
              )}
            />
          </Box>
        )}

        <Box
          sx={{
            backgroundColor: "secondary.main",
            color: "onPrimary.main",
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
          }}
        >
          <FaqLink
            appLocations={faqAppLocations}
            appLocationIdentifier="QuoteView-$CostPerPeriod-TopPinkBox"
          >
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}
            >
              <Typography
                variant="displayMedium"
                sx={{ lineHeight: "1.1", mr: 1 }}
              >
                {toDollars(peroidValues.netCost, 0)}
              </Typography>
              <Typography variant="titleLarge">
                per {peroidLabelMap[payCyclePeriod]}^
              </Typography>
            </Box>
          </FaqLink>
          <FaqLink
            appLocations={faqAppLocations}
            appLocationIdentifier="QuoteView-Includes$offinance&runningCostpa-Pinkbox"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                pt: 3,
                textAlign: "center",
              }}
            >
              <Typography variant="headlineMedium">
                Includes {toDollars(runningCostsPA, 0)}
              </Typography>
              <Typography variant="titleSmall">
                of finance & running costs p.a
              </Typography>
            </Box>
          </FaqLink>
        </Box>

        <Box>
          <APTextField<Inputs>
            name="quoteName"
            label="Quote Nickname"
            control={control}
            validations={{
              required: true,
              minLength: 1,
              maxLength: 100,
            }}
            errors={errors}
            defaultValue={name}
          />
        </Box>

        <Box>
          <APAccordion
            title={"Details"}
            leftIcon={!isLocked && <EditOutlinedIcon />}
            handleLeftIconClick={_handleSubmit(onEdit)}
          >
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-AnnualSalary-Details"
            >
              <APDetailRow
                label="Annual Salary (ex super)"
                value={toDollars(annualSalary, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-Annualkms-Details"
            >
              <APDetailRow
                label="Annual Kms"
                value={`${toFormattedNumber(annualKm, 0)} km`}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-PurchaseType-Details"
            >
              <APDetailRow
                label="Purchase Type"
                value={getPurchaseTypeLabel(purchaseType)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-OnroadCost-Details"
            >
              <APDetailRow
                label="On Road Cost"
                value={toDollars(vehicleOnroadCosts, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-LeaseTerm-Details"
            >
              <APDetailRow label="Lease Term (months)" value={`${term}`} />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-ResidualIncGST-Details"
            >
              <APDetailRow
                label="Residual Inc GST"
                value={toDollars(vehicleResidualGross, 0)}
              />{" "}
            </FaqLink>

            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-BusinessUsage%-Details"
            >
              <APDetailRow
                label="Business Usage %"
                value={`${businessUsage.toFixed(0)}%`}
              />
            </FaqLink>
          </APAccordion>
        </Box>

        <Box>
          <APAccordion
            title={"Includes"}
            defaultExpanded={peroidValues.inclusions.insurance === 0}
          >
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-Finance-Includes"
            >
              <APDetailRow
                label="Finance"
                value={toDollars(peroidValues.inclusions.finance, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-Servicing-Includes"
            >
              <APDetailRow
                label="Servicing"
                value={toDollars(peroidValues.inclusions.servicing, 0)}
              />{" "}
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-Tyres-Includes"
            >
              <APDetailRow
                label="Tyres"
                value={toDollars(peroidValues.inclusions.tyres, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-Fuel/Charging-Includes"
            >
              <APDetailRow
                label="Fuel / Charging"
                value={toDollars(peroidValues.inclusions.fuel, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-Rego&CTP-Includes"
            >
              <APDetailRow
                label="Rego & CTP"
                value={toDollars(peroidValues.inclusions.rego, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-ComprehensiveInsurance-Includes"
            >
              <APDetailRow
                label="Comprehensive Insurance"
                value={toDollars(peroidValues.inclusions.insurance, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-ManagementFee-Includes"
            >
              <APDetailRow
                label="Management Fee"
                value={toDollars(peroidValues.inclusions.managementFees, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-LuxuryCarCharge-Includes"
            >
              <APDetailRow
                label="Luxury Car Charge"
                value={toDollars(peroidValues.inclusions.luxuryCharge, 0)}
              />
            </FaqLink>
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-GST-Includes"
            >
              <APDetailRow
                label="GST"
                value={toDollars(peroidValues.inclusions.gst, 0)}
              />
            </FaqLink>
          </APAccordion>
        </Box>

        <Box>
          <Box sx={{ my: 2 }}>
            <APToggleGroup
              name="payCyclePeriod"
              control={control}
              options={payCyclePeriodOptions}
              defaultValue={payPeriod}
            />
          </Box>
        </Box>

        <Box>
          <APAccordion
            title={"Savings"}
            hideExpand={true}
            alwaysExpanded={true}
          >
            <FaqLink
              appLocations={faqAppLocations}
              appLocationIdentifier="QuoteView-PackageValue"
            >
              <APDetailRow
                label="Package Value"
                value={toDollars(peroidValues.packageValue, 0)}
              />
            </FaqLink>
          </APAccordion>
        </Box>

        <FaqLink
          appLocations={faqAppLocations}
          appLocationIdentifier="QuoteView-Saving%"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="titleLarge" color="secondary.main">
              Savings
            </Typography>

            <Box
              sx={{ p: 0.5, backgroundColor: "secondary.main", width: "6rem" }}
            >
              <Typography
                variant="headlineLarge"
                color="onPrimary.main"
                sx={{ whiteSpace: "nowrap", px: 1, textAlign: "right" }}
              >
                {savings.toFixed(0)}%
              </Typography>
            </Box>
          </Box>
        </FaqLink>

        <FaqLink
          appLocations={faqAppLocations}
          appLocationIdentifier="QuoteView-NetCost-BottomPage"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderTop: "solid black 1px",
              borderBottom: "solid black 1px",
              alignItems: "center",
            }}
          >
            <Typography variant="titleMedium">Net Cost^</Typography>
            <Box
              sx={{ backgroundColor: "primary.main", py: 0.5, width: "6rem" }}
            >
              <Typography
                variant="titleMedium"
                color="onPrimary.main"
                sx={{ textAlign: "right", pr: 1 }}
              >
                {toDollars(peroidValues.netCost, 0)}
              </Typography>
            </Box>
          </Box>
        </FaqLink>

        <FaqLink
          appLocations={faqAppLocations}
          appLocationIdentifier="QuoteView-Savings-Year-BottomPage"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="titleLarge">Savings - Year</Typography>
            <Typography variant="titleLarge" color="secondary.main">
              {toDollars(savingsPerYear, 0)}
            </Typography>
          </Box>
        </FaqLink>

        <FaqLink
          appLocations={faqAppLocations}
          appLocationIdentifier="QuoteView-LifeofLeaseSavings-BottomPage"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "secondary.main",
              py: 0.5,
              px: 1,
            }}
          >
            <Typography variant="titleLarge" color="onPrimary.main">
              Life of Lease Savings
            </Typography>
            <Typography variant="titleLarge" color="onPrimary.main">
              {toDollars(lifeOfLeaseSavings, 0)}
            </Typography>
          </Box>
        </FaqLink>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TermsButton termsId="quote" />
          <FaqLink
            appLocations={faqAppLocations}
            appLocationIdentifier="QuoteView-All"
            hideIfNoFaqs
          >
            <Typography variant="labelSmall" color="primary">
              FAQ
            </Typography>
          </FaqLink>
        </Box>

        {carouselFaqs.length > 0 && (
          <Box sx={{ backgroundColor: "surface.main" }}>
            {carouselAppLocationId && (
              <FaqCarousel
                faqs={carouselFaqs}
                appLocationId={carouselAppLocationId}
                handleFaqClick={handleCarouselFaqClick}
              />
            )}
            {carouselFaqs.length > 1 && (
              <Box sx={{ position: "relative " }}>
                <FaqLink
                  appLocations={faqAppLocations}
                  appLocationIdentifier="QuoteView-BelowLifeofLeaseSavings"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      right: 0,
                      bottom: 0,
                      position: "absolute",
                      mr: 2,
                      mb: 1,
                    }}
                  >
                    <Typography variant="bodySmall" color="primary">
                      More
                    </Typography>
                  </Box>
                </FaqLink>
              </Box>
            )}
          </Box>
        )}

        <Box>
          {isError && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="labelMedium" color="error.main">
                There are errors in your submission
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              pt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: 1,
                  mr: 2,
                }}
              >
                {!isLocked && (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    startIcon={<EditOutlinedIcon />}
                    sx={{ mr: 1 }}
                    onClick={_handleSubmit(onEdit)}
                  >
                    Edit
                  </Button>
                )}
                {canCreateQuote && (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    startIcon={<AddIcon />}
                    onClick={_handleSubmit(onNew)}
                  >
                    New
                  </Button>
                )}
              </Box>
              <RestrictedAppScope scope="internal">
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    rowGap: 1,
                    columnGap: 1,
                    mr: 2,
                  }}
                >
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    onClick={_handleSubmit(onUser)}
                  >
                    User
                  </Button>
                  <CatchELoadingButton
                    isLoading={isSyncingQuotes}
                    onClick={handleSyncQuotes}
                  />
                </Box>
              </RestrictedAppScope>
            </Box>
            <Box sx={{ alignSelf: "start" }}>
              <RestrictedAppScope scope="internal">
                <CatchEButton url={catchEQuoteUrl} />
              </RestrictedAppScope>

              <IconButton
                size="large"
                aria-label="download-quote"
                color="onSurface"
                onClick={_handleSubmit(onDownload)}
              >
                <DownloadIcon />
              </IconButton>

              <Button
                size="large"
                color="secondary"
                variant="contained"
                type="submit"
                disableElevation={true}
                startIcon={<PlayArrowOutlinedIcon />}
                onClick={_handleSubmit(onNext)}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
      {isSubmitting && <LoadingBackdrop />}
    </Box>
  );
};

export default CalculatedQuote;
