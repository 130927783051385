import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import {
  Location,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  isAbsoluteUrl,
  removeModalParam,
  replaceModalParam,
} from "../helpers/routes";

export const APModalStack = ({
  modalStack,
  appLocation,
}: {
  modalStack: string[];
  appLocation: Location<unknown>;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSingleModal = modalStack.length === 1;

  const onClose = () => {
    navigate(
      `${appLocation.pathname}?${removeModalParam(searchParams).toString()}`,
      { replace: true }
    );
  };

  const onBack = () => {
    if (isSingleModal) {
      onClose();
    } else {
      const previousModal = modalStack[modalStack.length - 2];

      navigate(
        `${appLocation.pathname}?${replaceModalParam(
          previousModal,
          searchParams
        ).toString()}`,
        { replace: true, state: { popModalStack: true } }
      );
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Paper
          sx={{
            py: 2,
            px: { xs: 2, sm: 4 },
            overflowY: "auto",
            maxWidth: "600px",
            maxHeight: "95vh",
            width: "95vw",
          }}
        >
          <Box
            onClick={(e) => {
              let target = e.target as HTMLElement;

              // Traverse up the DOM tree to find the <a> tag if a nested element is clicked
              while (target && target !== e.currentTarget) {
                if (target.tagName && target.tagName.toLowerCase() === "a") {
                  const href = target && target.getAttribute("href");

                  if (href && !isAbsoluteUrl(href)) {
                    e.preventDefault(); // Prevent default behavior (page reload)

                    const isModalLink = href.includes("modal=");
                    navigate(
                      `${isModalLink ? appLocation.pathname : ""}${href}`,
                      { replace: true }
                    );
                    return;
                  }
                }
                target = target.parentElement as HTMLElement;
              }
            }}
          >
            <Outlet />
          </Box>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Button
              size="large"
              color="primary"
              variant="outlined"
              disableElevation={true}
              onClick={onBack}
            >
              {isSingleModal ? "Close" : "Back"}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default APModalStack;
