import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography/Typography";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import APTextField from "../../../components/form/APTextField";
import { toDollars } from "../../../helpers/currencies";
import { isCreditCardProvidersRequired } from "../../../helpers/financeApplication";
import { AssetAndLiabilityType } from "../../../types/Dossier";
import { FinanceApplicationInputs } from "..";
import AssetAndLiabilityCard from "./AssetAndLiabilityCard";

const FinanceApplicationAssetsAndLiabilitiesForm = ({
  types,
  control,
  errors,
  watch,
  setValue,
  readOnly,
  highlightRequired,
}: {
  types: AssetAndLiabilityType[];
  control: Control<FinanceApplicationInputs>;
  errors: FieldErrors<FinanceApplicationInputs>;
  watch: UseFormWatch<FinanceApplicationInputs>;
  setValue: UseFormSetValue<FinanceApplicationInputs>;
  setError: UseFormSetError<FinanceApplicationInputs>;
  clearErrors: UseFormClearErrors<FinanceApplicationInputs>;
  readOnly: boolean;
  highlightRequired: boolean;
}) => {
  const existingAssetsAndLiabilities = watch("assetsAndLiabilities");
  const newAssetsAndLiabilities = watch("newAssetsAndLiabilities");
  const totalCreditCardLimit = watch("totalCreditCardLimit");

  const removeAssetAndLiability = (index: number, isNew: boolean) => {
    if (isNew) {
      setValue(`newAssetsAndLiabilities.${index}.toBeRemoved`, true);
    } else {
      setValue(`assetsAndLiabilities.${index}.toBeRemoved`, true);
    }
  };

  const addAssetAndLiability = () => {
    const newList = [...newAssetsAndLiabilities];
    newList.push({
      description: "",
      type: null,
      assetValue: "",
      liabilityValue: "",
      financier: "",
      incomeMonthly: "",
      mortgageMonthly: "",
      toBeRemoved: false,
    });
    setValue("newAssetsAndLiabilities", newList);
  };

  const renderCard = (index: number, isNew: boolean, toBeRemoved: boolean) => {
    const key = `${isNew ? "new" : "existing"}-${index}`;
    return toBeRemoved ? (
      <div key={key} />
    ) : (
      <Box sx={{ mt: 2 }} key={key}>
        <AssetAndLiabilityCard
          id={index}
          isNew={isNew}
          types={types}
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          onRemove={removeAssetAndLiability}
          readOnly={readOnly}
          highlightRequired={highlightRequired}
        />
      </Box>
    );
  };

  const calculateTotalAmount = (
    amountType: "asset" | "liability",
    listType: "existing" | "new"
  ) => {
    const fieldName = amountType === "asset" ? "assetValue" : "liabilityValue";
    const list =
      listType === "existing"
        ? existingAssetsAndLiabilities
        : newAssetsAndLiabilities;
    const totalAmount = list
      ? list.reduce((acc, item) => {
          return (
            acc +
            (!item.toBeRemoved && item[fieldName]
              ? parseInt(item[fieldName])
              : 0)
          );
        }, 0)
      : 0;
    return totalAmount;
  };

  const totalAssets =
    calculateTotalAmount("asset", "existing") +
    calculateTotalAmount("asset", "new");
  const totalLiabilities =
    calculateTotalAmount("liability", "existing") +
    calculateTotalAmount("liability", "new") +
    (totalCreditCardLimit ? parseInt(totalCreditCardLimit) : 0);
  const totalNetWorth = totalAssets - totalLiabilities;

  return (
    <Stack spacing={2}>
      <Typography variant="labelLarge">
        Please enter all your assets and liabilities.
        <ul>
          <li>Asset is something of value</li>
          <li>Liability is something that you owe</li>
        </ul>
      </Typography>

      <Box>
        {existingAssetsAndLiabilities &&
          existingAssetsAndLiabilities.map((value, index: number) =>
            renderCard(index, false, value.toBeRemoved)
          )}
        {newAssetsAndLiabilities &&
          newAssetsAndLiabilities.map((value, index: number) =>
            renderCard(index, true, value.toBeRemoved)
          )}
      </Box>

      {!readOnly && (
        <Box>
          <Button
            size="large"
            color="primary"
            variant="contained"
            disableElevation={true}
            startIcon={<AddIcon />}
            onClick={() => addAssetAndLiability()}
          >
            New
          </Button>
        </Box>
      )}

      <Stack spacing={2} sx={{ pt: 1 }}>
        <APTextField<FinanceApplicationInputs>
          name="creditCardProviders"
          label="Credit Card Provider(s)"
          control={control}
          validations={{
            maxLength: 100,
          }}
          errors={errors}
          defaultValue=""
          showRequired={isCreditCardProvidersRequired(totalCreditCardLimit)}
          highlightOnEmpty={
            highlightRequired &&
            isCreditCardProvidersRequired(totalCreditCardLimit)
          }
          readOnly={readOnly}
        />

        <APTextField<FinanceApplicationInputs>
          name="totalCreditCardLimit"
          label="Total Credit Card Limit"
          control={control}
          validations={{
            formatValidation: /[0-9]+(\.[0-9][0-9])?/,
          }}
          errors={errors}
          defaultValue={""}
          startAdornment="$"
          placeholder="xxxxxxx.xx"
          showRequired={true}
          highlightOnEmpty={highlightRequired}
          readOnly={readOnly}
          formatNumber={true}
        />

        <APTextField<FinanceApplicationInputs>
          name="totalCreditCardBalance"
          label="Total Credit Card Balance"
          control={control}
          validations={{
            formatValidation: /[0-9]+(\.[0-9][0-9])?/,
          }}
          errors={errors}
          defaultValue={""}
          startAdornment="$"
          placeholder="xxxxxxx.xx"
          showRequired={true}
          highlightOnEmpty={highlightRequired}
          readOnly={readOnly}
          formatNumber={true}
        />
      </Stack>

      <Stack spacing={2} sx={{ pt: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="bodyLarge">Total Assets</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="bodyLarge">
              {toDollars(totalAssets, 0)}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="bodyLarge">Total Liabilities</Typography>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="bodyLarge">
              {toDollars(totalLiabilities, 0)}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "solid black 1px",
            borderBottom: "solid black 1px",
            columnGap: 2,
            py: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="bodyLarge">Total Net Worth</Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="bodyLarge">
              {toDollars(totalNetWorth, 0)}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FinanceApplicationAssetsAndLiabilitiesForm;
