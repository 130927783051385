import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getAssetsAndLiabilitiesTypes } from "../../api/dossier/getAssetsAndLiabilitiesTypes";
import { getFinanceApplication } from "../../api/dossier/getFinanceApplication";
import {
  AssetsAndLiabilitiesUpdatePayload,
  FinanceApplicationFileUploads,
  updateFinanceApplication,
  UpdateFinanceApplicationPayload,
} from "../../api/dossier/updateFinanceApplication";
import BackButton from "../../components/buttons/BackButton";
import { AutoCompleteOption } from "../../components/form/APAutoComplete";
import APForm from "../../components/form/APForm";
import PageContainer from "../../components/PageContainer";
import { getAutoCompleteLabel } from "../../helpers/buildOptions";
import { downloadFile } from "../../helpers/download";
import { getRequiredFields } from "../../helpers/financeApplication";
import { trimStringInputs } from "../../helpers/form";
import { DeepPartial } from "../../helpers/typescript";
import Terms from "../../terms/components/Terms";
import {
  AddressResidenceStatus,
  ASSET_AND_LIABILITY_ID_PROPERTY,
  AssetAndLiability,
  AustralianResidencyStatus,
  DriversLicenseType,
  EmploymentType,
  FinanceApplicationIdentity,
  FinanceStatus,
  FinancialApplicationAddress,
  FinancialApplicationCreditCards,
  FinancialApplicationEmployment,
  FinancialApplicationIncome,
  FinancialApplicationPersonalInfo,
  MaritalStatus,
  METRO_FINANCIER,
  PayFrequency,
} from "../../types/Dossier";
import { State, stateOptions } from "../../types/State";
import FinanceApplicationAssetsAndLiabilitiesForm from "./components/FinanceApplicationAssetsAndLiabilitiesForm";
import FinanceApplicationEmploymentForm from "./components/FinanceApplicationEmploymentForm";
import FinanceApplicationIdentityForm, {
  licenseTypeOptions,
} from "./components/FinanceApplicationIdentityForm";
import FinanceApplicationIncomeForm from "./components/FinanceApplicationIncomeForm";
import FinanceApplicationInitialForm from "./components/FinanceApplicationInitialForm";
import FinanceApplicationResidenceForm from "./components/FinanceApplicationResidenceForm";

export const payFrequencyOptions: {
  id: PayFrequency;
  label: string;
}[] = [
  { id: "weekly", label: "Weekly" },
  { id: "fortnightly", label: "Fortnightly" },
  { id: "monthly", label: "Monthly" },
  { id: "bi-monthly", label: "Bi Monthly" },
];

export const employmentTypeOptions: {
  id: EmploymentType;
  label: string;
}[] = [
  { id: "permanent-ft", label: "Permanent Full-Time" },
  { id: "permanent-pt", label: "Permanent Part-Time" },
  { id: "casual", label: "Casual" },
  { id: "contractor", label: "Contractor" },
  { id: "partner", label: "Partner" },
  { id: "self-employed", label: "Sole Trader/Self Employed" },
  { id: "director", label: "Director" },
  { id: "unemployed", label: "Unemployed" },
  { id: "other", label: "Other" },
];

export type ExistingAssetAndLiability = {
  id: string;
  description: string;
  assetValue: string;
  liabilityValue: string;
  financier: string;
  incomeMonthly: string;
  mortgageMonthly: string;
  type: AutoCompleteOption | null;
  displayOrder: number | null;
  toBeRemoved: boolean;
};

export type NewAssetAndLiability = Omit<
  ExistingAssetAndLiability,
  "id" | "displayOrder"
>;

type InitialFormInputs = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Dayjs | null;
  mobile: string;
  phone: string;
  numberOfDependents: string;
  maritalStatus: MaritalStatus;
};

type IdentityFormInputs = {
  driversLicenseNumber: string;
  driversLicenseCardNumber: string;
  driversState: AutoCompleteOption | null;
  driversLicenseType: AutoCompleteOption | null;
  driversLicenseFrontImage: File | null;
  driversLicenseBackImage: File | null;
  residencyStatus: AustralianResidencyStatus;
  visaType: string;
  visaExpiry: Dayjs | null;
};

type ResidenceFormInputs = {
  currentStreetAddress: string;
  currentSuburb: string;
  currentState: AutoCompleteOption | null;
  currentPostcode: string;
  currentResidenceStatus: AddressResidenceStatus;
  currentYearsAt: string;
  currentMonthsAt: string;
  currentLandlord: string;
  currentLandlordPhone: string;
  previousStreetAddress: string;
  previousSuburb: string;
  previousState: AutoCompleteOption | null;
  previousPostcode: string;
  previousYearsAt: string;
  previousMonthsAt: string;
};

type EmploymentFormInputs = {
  currentEmployerName: string;
  currentEmploymentType: AutoCompleteOption | null;
  lastTaxReturnImage: File | null;
  prevTaxReturnImage: File | null;
  latestPayslipImage: File | null;
  prevPayslipImage: File | null;
  currentJobTitle: string;
  currentEmployerContactName: string;
  currentEmployerContactPhone: string;
  currentEmploymentYearsAt: string;
  currentEmploymentMonthsAt: string;
  annualSalaryTotal: string;
  annualSalaryPerPay: string;
  annualSalaryPayFrequency: AutoCompleteOption | null;
  netMonthlyPay: string;
  previousEmployerName: string;
  previousJobTitle: string;
  previousEmployerContactName: string;
  previousEmployerContactPhone: string;
  previousEmploymentYearsAt: string;
  previousEmploymentMonthsAt: string;
};

type IncomeFormInputs = {
  investmentPropertyIncome: string;
  investmentIncome: string;
  govtBenefitIncome: string;
  otherIncome: string;
  rentAmount: string;
  rentFrequency: AutoCompleteOption | null;
  rentMonthly: string;
  mortgageAmount: string;
  mortgageFrequency: AutoCompleteOption | null;
  mortgageMonthly: string;
  vehicleAmount: string;
  vehicleFrequency: AutoCompleteOption | null;
  vehicleMonthly: string;
  educationAmount: string;
  educationFrequency: AutoCompleteOption | null;
  educationMonthly: string;
  generalExpensesAmount: string;
  generalExpensesFrequency: AutoCompleteOption | null;
  generalExpensesMonthly: string;
  otherExpensesAmount: string;
  otherExpensesFrequency: AutoCompleteOption | null;
  otherExpensesMonthly: string;
  spouseIncludeIncome: boolean;
  spouseAnnualIncome: string;
  spouseSalaryPerPay: string;
  spousePayFrequency: AutoCompleteOption | null;
  spouseNetMonthlyPay: string;
  spouseEmploymentType: AutoCompleteOption | null;
  spouseLastTaxReturnImage: File | null;
  spousePrevTaxReturnImage: File | null;
  spouseLatestPayslipImage: File | null;
  spouseIncomeDeclarationImage: File | null;
  acceptTerms: boolean;
};

type AssetsAndLiabilitiesInputs = {
  assetsAndLiabilities: ExistingAssetAndLiability[];
  newAssetsAndLiabilities: NewAssetAndLiability[];
  creditCardProviders: string;
  totalCreditCardLimit: string;
  totalCreditCardBalance: string;
};

type InitialImageFiles = {
  initDriversLicenseFrontImage: File | null;
  initDriversLicenseBackImage: File | null;
  initLastTaxReturnImage: File | null;
  initPrevTaxReturnImage: File | null;
  initLatestPayslipImage: File | null;
  initPrevPayslipImage: File | null;
  initSpouseLastTaxReturnImage: File | null;
  initSpousePrevTaxReturnImage: File | null;
  initSpouseLatestPayslipImage: File | null;
  initSpouseIncomeDeclarationImage: File | null;
};

export type FinanceApplicationInputs = InitialFormInputs &
  IdentityFormInputs &
  ResidenceFormInputs &
  EmploymentFormInputs &
  IncomeFormInputs &
  AssetsAndLiabilitiesInputs &
  InitialImageFiles;

type FormStepId =
  | "personal"
  | "identity"
  | "residence"
  | "employment"
  | "income"
  | "assetsAndLiabilities";

type FormStep = {
  id: FormStepId;
  title: string;
  description?: string;
  children: ReactNode;
};

export type RequiredFields = {
  personal: (keyof InitialFormInputs)[];
  identity: (keyof IdentityFormInputs)[];
  residence: (keyof ResidenceFormInputs)[];
  employment: (keyof EmploymentFormInputs)[];
  income: (keyof IncomeFormInputs)[];
  assetsAndLiabilities: (keyof Omit<
    AssetsAndLiabilitiesInputs,
    "assetsAndLiabilities" | "newAssetsAndLiabilities"
  >)[];
  existingAssetsAndLiabilities: (keyof ExistingAssetAndLiability)[][];
  newAssetsAndLiabilities: (keyof NewAssetAndLiability)[][];
};

const FinanceApplication = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FinanceApplicationInputs>({ mode: "onTouched" });
  const [searchParams] = useSearchParams();
  const { dossierId } = useParams();
  const queryClient = useQueryClient();
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl } = useAuth();

  if (!dossierId) {
    // TODO: improve client side error messaging
    throw new Error("dossier id or next action param are misisng");
  }

  const financeApplication = getFinanceApplication(dossierId);

  const assetAndLiabilityTypes = getAssetsAndLiabilitiesTypes();

  if (financeApplication.isError) {
    throw financeApplication.error;
  }

  if (assetAndLiabilityTypes.isError) {
    throw assetAndLiabilityTypes.error;
  }

  const isLoading =
    financeApplication.isLoading || assetAndLiabilityTypes.isLoading;
  const isSuccess =
    financeApplication.isSuccess && assetAndLiabilityTypes.isSuccess;

  const onTermsClick = () => {
    setShowTermsModal(true);
  };
  const isLocked = financeApplication.data?.isLocked || false;

  const downloadSpousalIncomeDeclarationPDF = async () => {
    const pdf = await fetchWithAuth<Blob>(
      "getSpousalDeclarationPDF",
      dossierId,
      { responseOptions: { responseType: "blob" } }
    );

    downloadFile(pdf, "SpousalIncomeDeclarationForm.pdf");
  };
  const highlightRequired = financeApplication.data
    ? financeApplication.data.status === "submit-attempted"
    : false;
  const saveAndChangeStep = (stepId: string) => {
    const stepIndex = steps.findIndex((step) => step.id === stepId);

    if (saveButtonRef.current) {
      saveButtonRef.current.click(); // Trigger saving of form
      navigateToStep(stepIndex);
    }
  };

  const steps: FormStep[] = [
    {
      id: "personal",
      title: "Finance Application",
      description:
        "The next step to get you into your car is to apply for finance.",
      children: (
        <FinanceApplicationInitialForm
          control={control}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          readOnly={isLocked}
          highlightRequired={highlightRequired}
        />
      ),
    },
    {
      id: "identity",
      title: "Identity",
      description: "Proof of Identity is required for finance application.",
      children: (
        <FinanceApplicationIdentityForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          readOnly={isLocked}
          highlightRequired={highlightRequired}
        />
      ),
    },
    {
      id: "residence",
      title: "Residence",
      children: (
        <FinanceApplicationResidenceForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          readOnly={isLocked}
          highlightRequired={highlightRequired}
        />
      ),
    },
    {
      id: "assetsAndLiabilities",
      title: "Assets & Liabilities",
      children: (
        <FinanceApplicationAssetsAndLiabilitiesForm
          types={
            assetAndLiabilityTypes.isSuccess ? assetAndLiabilityTypes.data : []
          }
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          readOnly={isLocked}
          highlightRequired={highlightRequired}
        />
      ),
    },
    {
      id: "employment",
      title: "Employment",
      children: (
        <FinanceApplicationEmploymentForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          readOnly={isLocked}
          highlightRequired={highlightRequired}
        />
      ),
    },
    {
      id: "income",
      title: "Income & Expenses",
      children: (
        <FinanceApplicationIncomeForm
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          readOnly={isLocked}
          onDownloadSpousalIncomeDeclarationPDF={
            downloadSpousalIncomeDeclarationPDF
          }
          highlightRequired={highlightRequired}
          hideSpousalDeclaration={
            financeApplication.data?.financier === METRO_FINANCIER
          }
          onTermsClick={onTermsClick}
          onEditPropertyFields={saveAndChangeStep}
        />
      ),
    },
  ];

  const currentStepParam = searchParams.get("step");
  const parsedStepParam = currentStepParam ? parseInt(currentStepParam) : 0;
  const currentStepIndex =
    parsedStepParam < steps.length ? parsedStepParam : steps.length - 1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [parsedStepParam]);

  const { mutateAsync, isLoading: isUpdating } =
    updateFinanceApplication(dossierId);

  useEffect(() => {
    if (financeApplication.isSuccess && financeApplication.data) {
      if (financeApplication.data.isHidden) {
        navigate(getScopedPageUrl("viewDossier", dossierId), {
          replace: true,
        });
      } else {
        const {
          personal,
          identity,
          address,
          employment,
          income,
          assetsAndLiabilities,
          creditCards,
        } = financeApplication.data;

        // Personal
        setValue("firstName", personal.firstName || "");
        setValue("middleName", personal.middleName || "");
        setValue("lastName", personal.lastName || "");
        setValue("mobile", personal.mobile || "");
        setValue(
          "dateOfBirth",
          personal.dateOfBirth ? dayjs(personal.dateOfBirth) : null
        );
        setValue("phone", personal.phone || "");
        setValue(
          "numberOfDependents",
          personal.numberOfDependents || personal.numberOfDependents === 0
            ? `${personal.numberOfDependents}`
            : ""
        );

        if (personal.maritalStatus) {
          setValue("maritalStatus", personal.maritalStatus);
        }

        // Identity
        setValue("driversLicenseNumber", identity.driversLicense.number || "");
        setValue(
          "driversLicenseCardNumber",
          identity.driversLicense.cardNumber || ""
        );

        if (identity.driversLicense.state) {
          setValue("driversState", {
            id: identity.driversLicense.state,
            label: getAutoCompleteLabel(
              stateOptions,
              identity.driversLicense.state
            ),
          });
        }

        if (identity.driversLicense.type) {
          setValue("driversLicenseType", {
            id: identity.driversLicense.type,
            label: getAutoCompleteLabel(
              licenseTypeOptions,
              identity.driversLicense.type
            ),
          });
        }

        if (identity.driversLicense.frontImageName) {
          const frontImage = new File(
            [""],
            identity.driversLicense.frontImageName
          );
          setValue("driversLicenseFrontImage", frontImage);
          setValue("initDriversLicenseFrontImage", frontImage);
        }

        if (identity.driversLicense.backImageName) {
          const backImage = new File(
            [""],
            identity.driversLicense.backImageName
          );
          setValue("driversLicenseBackImage", backImage);
          setValue("initDriversLicenseBackImage", backImage);
        }

        if (identity.residency.status) {
          setValue("residencyStatus", identity.residency.status);
        }
        setValue("visaType", identity.residency.visaType || "");
        setValue(
          "visaExpiry",
          identity.residency.visaExpiry
            ? dayjs(identity.residency.visaExpiry)
            : null
        );
        // Address
        setValue("currentStreetAddress", address.current.streetAddress || "");
        setValue("currentSuburb", address.current.suburb || "");

        if (address.current.state) {
          setValue("currentState", {
            id: address.current.state,
            label: getAutoCompleteLabel(stateOptions, address.current.state),
          });
        }

        setValue("currentPostcode", address.current.postcode || "");

        if (address.current.residenceStatus) {
          setValue("currentResidenceStatus", address.current.residenceStatus);
        }

        setValue(
          "currentYearsAt",
          address.current.yearsAt || address.current.yearsAt === 0
            ? `${address.current.yearsAt}`
            : ""
        );
        setValue(
          "currentMonthsAt",
          address.current.monthsAt || address.current.monthsAt === 0
            ? `${address.current.monthsAt}`
            : ""
        );
        setValue("currentLandlord", address.current.landlord || "");
        setValue("currentLandlordPhone", address.current.landlordPhone || "");
        setValue("previousStreetAddress", address.previous.streetAddress || "");
        setValue("previousSuburb", address.previous.suburb || "");

        if (address.previous.state) {
          setValue("previousState", {
            id: address.previous.state,
            label: getAutoCompleteLabel(stateOptions, address.previous.state),
          });
        }

        setValue("previousPostcode", address.previous.postcode || "");
        setValue(
          "previousYearsAt",
          address.previous.yearsAt || address.previous.yearsAt === 0
            ? `${address.previous.yearsAt}`
            : ""
        );
        setValue(
          "previousMonthsAt",
          address.previous.monthsAt || address.previous.monthsAt === 0
            ? `${address.previous.monthsAt}`
            : ""
        );

        // Employment
        setValue("currentEmployerName", employment.current.employerName || "");

        if (employment.current.type) {
          setValue("currentEmploymentType", {
            id: employment.current.type,
            label: getAutoCompleteLabel(
              employmentTypeOptions,
              employment.current.type
            ),
          });
        }

        setValue("currentJobTitle", employment.current.jobTitle || "");
        setValue(
          "currentEmployerContactName",
          employment.current.employerContactName || ""
        );
        setValue(
          "currentEmployerContactPhone",
          employment.current.employerContactPhone || ""
        );

        if (employment.current.lastTaxReturnName) {
          const taxReturn = new File(
            [""],
            employment.current.lastTaxReturnName
          );
          setValue("lastTaxReturnImage", taxReturn);
          setValue("initLastTaxReturnImage", taxReturn);
        }

        if (employment.current.prevTaxReturnName) {
          const backImage = new File(
            [""],
            employment.current.prevTaxReturnName
          );
          setValue("prevTaxReturnImage", backImage);
          setValue("initPrevTaxReturnImage", backImage);
        }

        if (employment.latestPayslipName) {
          const payslipImage = new File([""], employment.latestPayslipName);
          setValue("latestPayslipImage", payslipImage);
          setValue("initLatestPayslipImage", payslipImage);
        }

        if (employment.prevPayslipName) {
          const payslipImage = new File([""], employment.prevPayslipName);
          setValue("prevPayslipImage", payslipImage);
          setValue("initPrevPayslipImage", payslipImage);
        }

        setValue(
          "currentEmploymentYearsAt",
          employment.current.yearsAt || employment.current.yearsAt === 0
            ? `${employment.current.yearsAt}`
            : ""
        );
        setValue(
          "currentEmploymentMonthsAt",
          employment.current.monthsAt || employment.current.monthsAt === 0
            ? `${employment.current.monthsAt}`
            : ""
        );
        setValue(
          "annualSalaryTotal",
          employment.annualSalary.total || employment.annualSalary.total === 0
            ? `${employment.annualSalary.total}`
            : ""
        );
        setValue(
          "annualSalaryPerPay",
          employment.annualSalary.perPay || employment.annualSalary.perPay === 0
            ? `${employment.annualSalary.perPay}`
            : ""
        );

        if (employment.annualSalary.payFrequency) {
          setValue("annualSalaryPayFrequency", {
            id: employment.annualSalary.payFrequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              employment.annualSalary.payFrequency
            ),
          });
        }

        setValue(
          "previousEmployerName",
          employment.previous.employerName || ""
        );
        setValue("previousJobTitle", employment.previous.jobTitle || "");
        setValue(
          "previousEmployerContactName",
          employment.previous.employerContactName || ""
        );
        setValue(
          "previousEmployerContactPhone",
          employment.previous.employerContactPhone || ""
        );

        setValue(
          "previousEmploymentYearsAt",
          employment.previous.yearsAt || employment.previous.yearsAt === 0
            ? `${employment.previous.yearsAt}`
            : ""
        );
        setValue(
          "previousEmploymentMonthsAt",
          employment.previous.monthsAt || employment.previous.monthsAt === 0
            ? `${employment.previous.monthsAt}`
            : ""
        );

        // Income
        setValue(
          "investmentPropertyIncome",
          income.investmentPropertyIncome ||
            income.investmentPropertyIncome === 0
            ? `${income.investmentPropertyIncome}`
            : ""
        );
        setValue(
          "investmentIncome",
          income.investmentIncome || income.investmentIncome === 0
            ? `${income.investmentIncome}`
            : ""
        );
        setValue(
          "govtBenefitIncome",
          income.govtBenefitIncome || income.govtBenefitIncome === 0
            ? `${income.govtBenefitIncome}`
            : ""
        );
        setValue(
          "otherIncome",
          income.otherIncome || income.otherIncome === 0
            ? `${income.otherIncome}`
            : ""
        );
        setValue(
          "rentAmount",
          income.rent.amount || income.rent.amount === 0
            ? `${income.rent.amount}`
            : ""
        );
        if (income.rent.frequency) {
          setValue("rentFrequency", {
            id: income.rent.frequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.rent.frequency
            ),
          });
        }
        setValue(
          "rentMonthly",
          income.rent.monthlyAmount || income.rent.monthlyAmount === 0
            ? `${income.rent.monthlyAmount}`
            : ""
        );
        setValue(
          "mortgageAmount",
          income.mortgage.amount || income.mortgage.amount === 0
            ? `${income.mortgage.amount}`
            : ""
        );
        if (income.mortgage.frequency) {
          setValue("mortgageFrequency", {
            id: income.mortgage.frequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.mortgage.frequency
            ),
          });
        }
        setValue(
          "mortgageMonthly",
          income.mortgage.monthlyAmount || income.mortgage.monthlyAmount === 0
            ? `${income.mortgage.monthlyAmount}`
            : ""
        );
        setValue(
          "vehicleAmount",
          income.vehicleCosts.amount || income.vehicleCosts.amount === 0
            ? `${income.vehicleCosts.amount}`
            : ""
        );
        if (income.vehicleCosts.frequency) {
          setValue("vehicleFrequency", {
            id: income.vehicleCosts.frequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.vehicleCosts.frequency
            ),
          });
        }
        setValue(
          "vehicleMonthly",
          income.vehicleCosts.monthlyAmount ||
            income.vehicleCosts.monthlyAmount === 0
            ? `${income.vehicleCosts.monthlyAmount}`
            : ""
        );
        setValue(
          "educationAmount",
          income.educationCosts.amount || income.educationCosts.amount === 0
            ? `${income.educationCosts.amount}`
            : ""
        );
        if (income.educationCosts.frequency) {
          setValue("educationFrequency", {
            id: income.educationCosts.frequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.educationCosts.frequency
            ),
          });
        }
        setValue(
          "educationMonthly",
          income.educationCosts.monthlyAmount ||
            income.educationCosts.monthlyAmount === 0
            ? `${income.educationCosts.monthlyAmount}`
            : ""
        );
        setValue(
          "generalExpensesAmount",
          income.generalExpenses.amount || income.generalExpenses.amount === 0
            ? `${income.generalExpenses.amount}`
            : ""
        );
        if (income.generalExpenses.frequency) {
          setValue("generalExpensesFrequency", {
            id: income.generalExpenses.frequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.generalExpenses.frequency
            ),
          });
        }
        setValue(
          "generalExpensesMonthly",
          income.generalExpenses.monthlyAmount ||
            income.generalExpenses.monthlyAmount === 0
            ? `${income.generalExpenses.monthlyAmount}`
            : ""
        );
        setValue(
          "otherExpensesAmount",
          income.otherExpenses.amount || income.otherExpenses.amount === 0
            ? `${income.otherExpenses.amount}`
            : ""
        );
        if (income.otherExpenses.frequency) {
          setValue("otherExpensesFrequency", {
            id: income.otherExpenses.frequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.otherExpenses.frequency
            ),
          });
        }
        setValue(
          "otherExpensesMonthly",
          income.otherExpenses.monthlyAmount ||
            income.otherExpenses.monthlyAmount === 0
            ? `${income.otherExpenses.monthlyAmount}`
            : ""
        );
        setValue("spouseIncludeIncome", income.spouse.include);
        setValue(
          "spouseAnnualIncome",
          income.spouse.annualSalary || income.spouse.annualSalary === 0
            ? `${income.spouse.annualSalary}`
            : ""
        );
        setValue(
          "spouseSalaryPerPay",
          income.spouse.salaryPerPay || income.spouse.salaryPerPay === 0
            ? `${income.spouse.salaryPerPay}`
            : ""
        );
        if (income.spouse.payFrequency) {
          setValue("spousePayFrequency", {
            id: income.spouse.payFrequency,
            label: getAutoCompleteLabel(
              payFrequencyOptions,
              income.spouse.payFrequency
            ),
          });
        }
        setValue(
          "spouseNetMonthlyPay",
          income.spouse.netPayPerMonth || income.spouse.netPayPerMonth === 0
            ? `${income.spouse.netPayPerMonth}`
            : ""
        );

        if (income.spouse.employmentType) {
          setValue("spouseEmploymentType", {
            id: income.spouse.employmentType,
            label: getAutoCompleteLabel(
              employmentTypeOptions,
              income.spouse.employmentType
            ),
          });
        }

        if (income.spouse.lastTaxReturnName) {
          const taxReturnImage = new File(
            [""],
            income.spouse.lastTaxReturnName
          );
          setValue("spouseLastTaxReturnImage", taxReturnImage);
          setValue("initSpouseLastTaxReturnImage", taxReturnImage);
        }

        if (income.spouse.prevTaxReturnName) {
          const taxReturnImage = new File(
            [""],
            income.spouse.prevTaxReturnName
          );
          setValue("spousePrevTaxReturnImage", taxReturnImage);
          setValue("initSpousePrevTaxReturnImage", taxReturnImage);
        }

        if (income.spouse.latestPayslipName) {
          const payslipImage = new File([""], income.spouse.latestPayslipName);
          setValue("spouseLatestPayslipImage", payslipImage);
          setValue("initSpouseLatestPayslipImage", payslipImage);
        }

        if (income.spouse.incomeDeclarationName) {
          const declarationImage = new File(
            [""],
            income.spouse.incomeDeclarationName
          );
          setValue("spouseIncomeDeclarationImage", declarationImage);
          setValue("initSpouseIncomeDeclarationImage", declarationImage);
        }

        // Assets and Liabilities
        assetsAndLiabilities.forEach((value, index) => {
          const parsedValue: ExistingAssetAndLiability = {
            id: value.id,
            description: value.description || "",
            type: value.typeId
              ? { id: `${value.typeId}`, label: value.typeLabel || "" }
              : null,
            assetValue:
              value.assetValue || value.assetValue === 0
                ? `${value.assetValue}`
                : "",
            financier: value.financier || "",
            liabilityValue:
              value.liabilityValue || value.liabilityValue === 0
                ? `${value.liabilityValue}`
                : "",
            displayOrder: value.displayOrder,
            incomeMonthly:
              value.incomePerMth || value.incomePerMth === 0
                ? `${value.incomePerMth}`
                : "",
            mortgageMonthly:
              value.expensePerMth || value.expensePerMth === 0
                ? `${value.expensePerMth}`
                : "",
            toBeRemoved: false,
          };
          setValue(`assetsAndLiabilities.${index}`, parsedValue);
        });

        setValue("newAssetsAndLiabilities", []);

        // Credit Cards
        setValue("creditCardProviders", creditCards.creditCardProviders || "");
        setValue(
          "totalCreditCardLimit",
          creditCards.totalCreditCardLimit ||
            creditCards.totalCreditCardLimit === 0
            ? `${creditCards.totalCreditCardLimit}`
            : ""
        );
        setValue(
          "totalCreditCardBalance",
          creditCards.totalCreditCardBalance ||
            creditCards.totalCreditCardBalance === 0
            ? `${creditCards.totalCreditCardBalance}`
            : ""
        );
      }
    }
  }, [isSuccess]);

  const currentStep = steps[currentStepIndex];

  const renderFormStep = (step: FormStep, index: number) => {
    const isCurrentStep = currentStepIndex === index;
    return <Box key={index}>{isCurrentStep && step.children}</Box>;
  };

  const navigateToStep = (step: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set("step", `${step}`);

    navigate({ search: "?" + updatedSearchParams.toString() });
  };

  const getMissingRequiredFields = (): Record<FormStepId, string[]> => {
    const currentValues = getValues();
    const {
      existingAssetsAndLiabilities,
      newAssetsAndLiabilities,
      ...requiredFields
    } = getRequiredFields(currentValues, assetAndLiabilityTypes.data || []);
    const missingFields: Record<FormStepId, string[]> = {
      personal: [],
      identity: [],
      residence: [],
      employment: [],
      income: [],
      assetsAndLiabilities: [],
    };

    Object.keys(requiredFields).forEach((formStepId) => {
      const fields =
        requiredFields[
          formStepId as keyof Omit<
            RequiredFields,
            "existingAssetsAndLiabilities" | "newAssetsAndLiabilities"
          >
        ];
      fields.forEach((field) => {
        if (!currentValues[field]) {
          missingFields[
            formStepId as keyof Omit<
              RequiredFields,
              "existingAssetsAndLiabilities" | "newAssetsAndLiabilities"
            >
          ].push(field);
        }
      });
    });

    existingAssetsAndLiabilities.forEach((fields, index) => {
      const currentItem = currentValues.assetsAndLiabilities[index];
      fields.forEach((field) => {
        if (!currentItem || !currentItem[field]) {
          missingFields.assetsAndLiabilities.push(`existing.${index}.${field}`);
        }
      });
    });

    newAssetsAndLiabilities.forEach((fields, index) => {
      const currentItem = currentValues.newAssetsAndLiabilities[index];

      fields.forEach((field) => {
        if (!currentItem || !currentItem[field]) {
          missingFields.assetsAndLiabilities.push(`new.${index}.${field}`);
        }
      });
    });

    return missingFields;
  };

  const onSubmit = async (
    _data: FinanceApplicationInputs,
    financeStatus?: FinanceStatus
  ) => {
    trimStringInputs(_data, setValue);
    const data = getValues();

    if (
      financeApplication.isSuccess &&
      financeApplication.data &&
      !financeApplication.data.isLocked
    ) {
      const updatePayload: UpdateFinanceApplicationPayload = {};

      const {
        firstName,
        middleName,
        lastName,
        dateOfBirth,
        mobile,
        phone,
        numberOfDependents,
        maritalStatus,
      } = financeApplication.data.personal;
      const { driversLicense, residency } = financeApplication.data.identity;
      const { current, previous } = financeApplication.data.address;
      const {
        current: currentEmployment,
        previous: previousEmployment,
        annualSalary,
      } = financeApplication.data.employment;
      const {
        investmentPropertyIncome,
        govtBenefitIncome,
        otherIncome,
        rent: currentRent,
        vehicleCosts: currentVehicleCosts,
        mortgage: currentMortgage,
        educationCosts: currentEducationCosts,
        generalExpenses: currentGeneralExpenses,
        otherExpenses: currentOtherExpenses,
        spouse: currentSpouse,
      } = financeApplication.data.income;
      const currentAssetsAndLiabilities =
        financeApplication.data.assetsAndLiabilities;
      const {
        creditCardProviders,
        totalCreditCardLimit,
        totalCreditCardBalance,
      } = financeApplication.data.creditCards;

      const personal: DeepPartial<FinancialApplicationPersonalInfo> = {};
      const identity: DeepPartial<FinanceApplicationIdentity> = {};
      identity.driversLicense = {};
      identity.residency = {};
      const uploads: FinanceApplicationFileUploads = {};
      const address: DeepPartial<FinancialApplicationAddress> = {};
      address.current = {};
      address.previous = {};
      const employment: DeepPartial<FinancialApplicationEmployment> = {};
      employment.current = {};
      employment.previous = {};
      employment.annualSalary = {};
      const income: DeepPartial<FinancialApplicationIncome> = {};
      income.rent = {};
      income.mortgage = {};
      income.vehicleCosts = {};
      income.educationCosts = {};
      income.generalExpenses = {};
      income.otherExpenses = {};
      income.spouse = {};
      const assetsAndLiabilities: AssetsAndLiabilitiesUpdatePayload = {};
      const creditCards: DeepPartial<FinancialApplicationCreditCards> = {};

      const oldDateOfBirth = dateOfBirth
        ? dayjs(dateOfBirth).toISOString()
        : null;
      const newDateOfBirth =
        data.dateOfBirth && data.dateOfBirth.isValid()
          ? data.dateOfBirth.toISOString()
          : null;

      const oldVisaExpiry = residency.visaExpiry
        ? dayjs(residency.visaExpiry).toISOString()
        : null;
      const newVisaExpiry =
        data.visaExpiry && data.visaExpiry.isValid()
          ? data.visaExpiry.toISOString()
          : null;

      if (financeStatus) {
        updatePayload.status = financeStatus;
      }

      switch (currentStep.id) {
        case "personal":
          if (`${data.firstName}` !== (firstName || "")) {
            personal.firstName = data.firstName;
          }

          if (`${data.middleName}` !== (middleName || "")) {
            personal.middleName = data.middleName;
          }

          if (`${data.lastName}` !== (lastName || "")) {
            personal.lastName = data.lastName;
          }

          if (newDateOfBirth !== oldDateOfBirth) {
            personal.dateOfBirth = newDateOfBirth;
          }

          if (`${data.mobile}` !== (mobile || "")) {
            personal.mobile = data.mobile;
          }

          if (`${data.phone}` !== (phone || "")) {
            personal.phone = data.phone;
          }

          if (`${data.numberOfDependents}` !== `${numberOfDependents || ""}`) {
            personal.numberOfDependents = data.numberOfDependents
              ? parseInt(data.numberOfDependents)
              : null;
          }

          if (`${data.maritalStatus}` !== `${maritalStatus}`) {
            personal.maritalStatus = data.maritalStatus;
          }

          if (Object.keys(personal).length > 0) {
            updatePayload.personal = personal;
          }
          break;
        case "identity":
          if (
            `${data.driversLicenseNumber}` !== (driversLicense.number || "")
          ) {
            identity.driversLicense.number = data.driversLicenseNumber;
          }

          if (
            `${data.driversLicenseCardNumber}` !==
            (driversLicense.cardNumber || "")
          ) {
            identity.driversLicense.cardNumber = data.driversLicenseCardNumber;
          }

          if (
            `${data.driversState ? data.driversState.id : null}` !==
            `${driversLicense.state}`
          ) {
            identity.driversLicense.state = data.driversState
              ? (data.driversState.id as State)
              : null;
          }

          if (
            `${data.driversLicenseType ? data.driversLicenseType.id : null}` !==
            `${driversLicense.type}`
          ) {
            identity.driversLicense.type = data.driversLicenseType
              ? (data.driversLicenseType.id as DriversLicenseType)
              : null;
          }

          if (
            data.driversLicenseFrontImage &&
            data.driversLicenseFrontImage.lastModified !==
              data.initDriversLicenseFrontImage?.lastModified
          ) {
            uploads.driversLicenseFrontImage = data.driversLicenseFrontImage;
          }

          if (
            data.driversLicenseBackImage &&
            data.driversLicenseBackImage.lastModified !==
              data.initDriversLicenseBackImage?.lastModified
          ) {
            uploads.driversLicenseBackImage = data.driversLicenseBackImage;
          }

          if (`${data.residencyStatus}` !== `${residency.status}`) {
            identity.residency.status = data.residencyStatus;
          }

          if (`${data.visaType}` !== (residency.visaType || "")) {
            identity.residency.visaType = data.visaType;
          }

          if (newVisaExpiry !== oldVisaExpiry) {
            identity.residency.visaExpiry = newVisaExpiry;
          }

          if (
            Object.keys(identity.driversLicense).length > 0 ||
            Object.keys(identity.residency).length > 0
          ) {
            updatePayload.identity = {};

            if (Object.keys(identity.driversLicense).length > 0) {
              updatePayload.identity.driversLicense = identity.driversLicense;
            }
            if (Object.keys(identity.residency).length > 0) {
              updatePayload.identity.residency = identity.residency;
            }
          }
          break;
        case "residence":
          if (
            `${data.currentStreetAddress}` !== (current.streetAddress || "")
          ) {
            address.current.streetAddress = data.currentStreetAddress;
          }

          if (`${data.currentSuburb}` !== (current.suburb || "")) {
            address.current.suburb = data.currentSuburb;
          }

          if (
            `${data.currentState ? data.currentState.id : null}` !==
            `${current.state}`
          ) {
            address.current.state = data.currentState
              ? (data.currentState.id as State)
              : null;
          }

          if (`${data.currentPostcode}` !== (current.postcode || "")) {
            address.current.postcode = data.currentPostcode;
          }

          if (
            `${data.currentResidenceStatus}` !==
            `${current.residenceStatus || ""}`
          ) {
            address.current.residenceStatus = data.currentResidenceStatus;
          }

          if (`${data.currentYearsAt}` !== `${current.yearsAt || ""}`) {
            address.current.yearsAt = data.currentYearsAt
              ? parseInt(data.currentYearsAt)
              : null;
          }

          if (`${data.currentMonthsAt}` !== `${current.monthsAt || ""}`) {
            address.current.monthsAt = data.currentMonthsAt
              ? parseInt(data.currentMonthsAt)
              : null;
          }

          if (`${data.currentLandlord}` !== (current.landlord || "")) {
            address.current.landlord = data.currentLandlord;
          }

          if (
            `${data.currentLandlordPhone}` !== (current.landlordPhone || "")
          ) {
            address.current.landlordPhone = data.currentLandlordPhone;
          }

          if (
            `${data.previousStreetAddress}` !== (previous.streetAddress || "")
          ) {
            address.previous.streetAddress = data.previousStreetAddress;
          }

          if (`${data.previousSuburb}` !== (previous.suburb || "")) {
            address.previous.suburb = data.previousSuburb;
          }

          if (
            `${data.previousState ? data.previousState.id : null}` !==
            `${previous.state}`
          ) {
            address.previous.state = data.previousState
              ? (data.previousState.id as State)
              : null;
          }

          if (`${data.previousPostcode}` !== (previous.postcode || "")) {
            address.previous.postcode = data.previousPostcode;
          }

          if (`${data.previousYearsAt}` !== `${previous.yearsAt || ""}`) {
            address.previous.yearsAt = data.previousYearsAt
              ? parseInt(data.previousYearsAt)
              : null;
          }

          if (`${data.previousMonthsAt}` !== `${previous.monthsAt || ""}`) {
            address.previous.monthsAt = data.previousMonthsAt
              ? parseInt(data.previousMonthsAt)
              : null;
          }

          if (
            Object.keys(address.current).length > 0 ||
            Object.keys(address.previous).length > 0
          ) {
            updatePayload.address = {};

            if (Object.keys(address.current).length > 0) {
              updatePayload.address.current = address.current;
            }
            if (Object.keys(address.previous).length > 0) {
              updatePayload.address.previous = address.previous;
            }
          }
          break;
        case "employment":
          if (
            `${data.currentEmployerName}` !==
            (currentEmployment.employerName || "")
          ) {
            employment.current.employerName = data.currentEmployerName;
          }

          if (
            `${
              data.currentEmploymentType ? data.currentEmploymentType.id : null
            }` !== `${currentEmployment.type}`
          ) {
            employment.current.type = data.currentEmploymentType
              ? (data.currentEmploymentType.id as EmploymentType)
              : null;
          }

          if (
            data.lastTaxReturnImage &&
            data.lastTaxReturnImage.lastModified !==
              data.initLastTaxReturnImage?.lastModified
          ) {
            uploads.lastTaxReturnImage = data.lastTaxReturnImage;
          }

          if (
            data.prevTaxReturnImage &&
            data.prevTaxReturnImage.lastModified !==
              data.initPrevTaxReturnImage?.lastModified
          ) {
            uploads.prevTaxReturnImage = data.prevTaxReturnImage;
          }

          if (
            data.latestPayslipImage &&
            data.latestPayslipImage.lastModified !==
              data.initLatestPayslipImage?.lastModified
          ) {
            uploads.latestPayslipImage = data.latestPayslipImage;
          }

          if (
            data.prevPayslipImage &&
            data.prevPayslipImage.lastModified !==
              data.initPrevPayslipImage?.lastModified
          ) {
            uploads.prevPayslipImage = data.prevPayslipImage;
          }

          if (
            `${data.currentJobTitle}` !== (currentEmployment.jobTitle || "")
          ) {
            employment.current.jobTitle = data.currentJobTitle;
          }

          if (
            `${data.currentEmployerContactName}` !==
            (currentEmployment.employerContactName || "")
          ) {
            employment.current.employerContactName =
              data.currentEmployerContactName;
          }

          if (
            `${data.currentEmployerContactPhone}` !==
            (currentEmployment.employerContactPhone || "")
          ) {
            employment.current.employerContactPhone =
              data.currentEmployerContactPhone;
          }

          if (
            `${data.currentEmploymentYearsAt}` !==
            `${currentEmployment.yearsAt || ""}`
          ) {
            employment.current.yearsAt = data.currentEmploymentYearsAt
              ? parseInt(data.currentEmploymentYearsAt)
              : null;
          }

          if (
            `${data.currentEmploymentMonthsAt}` !==
            `${currentEmployment.monthsAt || ""}`
          ) {
            employment.current.monthsAt = data.currentEmploymentMonthsAt
              ? parseInt(data.currentEmploymentMonthsAt)
              : null;
          }

          if (`${data.annualSalaryTotal}` !== `${annualSalary.total || ""}`) {
            employment.annualSalary.total = data.annualSalaryTotal
              ? parseInt(data.annualSalaryTotal)
              : null;
          }

          if (`${data.annualSalaryPerPay}` !== `${annualSalary.perPay || ""}`) {
            employment.annualSalary.perPay = data.annualSalaryPerPay
              ? parseInt(data.annualSalaryPerPay)
              : null;
          }

          if (
            `${
              data.annualSalaryPayFrequency
                ? data.annualSalaryPayFrequency.id
                : null
            }` !== `${annualSalary.payFrequency}`
          ) {
            employment.annualSalary.payFrequency = data.annualSalaryPayFrequency
              ? (data.annualSalaryPayFrequency.id as PayFrequency)
              : null;
          }

          if (
            `${data.netMonthlyPay}` !== `${annualSalary.netPayPerMonth || ""}`
          ) {
            employment.annualSalary.netPayPerMonth = data.netMonthlyPay
              ? parseInt(data.netMonthlyPay)
              : null;
          }

          if (
            `${data.previousEmployerName}` !==
            (previousEmployment.employerName || "")
          ) {
            employment.previous.employerName = data.previousEmployerName;
          }

          if (
            `${data.previousJobTitle}` !== (previousEmployment.jobTitle || "")
          ) {
            employment.previous.jobTitle = data.previousJobTitle;
          }

          if (
            `${data.previousEmployerContactName}` !==
            (previousEmployment.employerContactName || "")
          ) {
            employment.previous.employerContactName =
              data.previousEmployerContactName;
          }

          if (
            `${data.previousEmployerContactPhone}` !==
            (previousEmployment.employerContactPhone || "")
          ) {
            employment.previous.employerContactPhone =
              data.previousEmployerContactPhone;
          }

          if (
            `${data.previousEmploymentYearsAt}` !==
            `${previousEmployment.yearsAt || ""}`
          ) {
            employment.previous.yearsAt = data.previousEmploymentYearsAt
              ? parseInt(data.previousEmploymentYearsAt)
              : null;
          }

          if (
            `${data.previousEmploymentMonthsAt}` !==
            `${previousEmployment.monthsAt || ""}`
          ) {
            employment.previous.monthsAt = data.previousEmploymentMonthsAt
              ? parseInt(data.previousEmploymentMonthsAt)
              : null;
          }

          if (
            Object.keys(employment.current).length > 0 ||
            Object.keys(employment.previous).length > 0 ||
            Object.keys(employment.annualSalary).length > 0
          ) {
            updatePayload.employment = {};

            if (Object.keys(employment.current).length > 0) {
              updatePayload.employment.current = employment.current;
            }
            if (Object.keys(employment.previous).length > 0) {
              updatePayload.employment.previous = employment.previous;
            }
            if (Object.keys(employment.annualSalary).length > 0) {
              updatePayload.employment.annualSalary = employment.annualSalary;
            }
          }
          break;
        case "income":
          if (`${data.govtBenefitIncome}` !== `${govtBenefitIncome || ""}`) {
            income.govtBenefitIncome = data.govtBenefitIncome
              ? parseInt(data.govtBenefitIncome)
              : null;
          }

          if (`${data.otherIncome}` !== `${otherIncome || ""}`) {
            income.otherIncome = data.otherIncome
              ? parseInt(data.otherIncome)
              : null;
          }

          if (`${data.rentAmount}` !== `${currentRent.amount || ""}`) {
            income.rent.amount = data.rentAmount
              ? parseInt(data.rentAmount)
              : null;
          }
          if (
            `${data.rentFrequency ? data.rentFrequency.id : null}` !==
            `${currentRent.frequency}`
          ) {
            income.rent.frequency = data.rentFrequency
              ? (data.rentFrequency.id as PayFrequency)
              : null;
          }
          if (`${data.rentMonthly}` !== `${currentRent.monthlyAmount || ""}`) {
            income.rent.monthlyAmount = data.rentMonthly
              ? parseInt(data.rentMonthly)
              : null;
          }

          if (
            `${data.vehicleAmount}` !== `${currentVehicleCosts.amount || ""}`
          ) {
            income.vehicleCosts.amount = data.vehicleAmount
              ? parseInt(data.vehicleAmount)
              : null;
          }
          if (
            `${data.vehicleFrequency ? data.vehicleFrequency.id : null}` !==
            `${currentVehicleCosts.frequency}`
          ) {
            income.vehicleCosts.frequency = data.vehicleFrequency
              ? (data.vehicleFrequency.id as PayFrequency)
              : null;
          }
          if (
            `${data.vehicleMonthly}` !==
            `${currentVehicleCosts.monthlyAmount || ""}`
          ) {
            income.vehicleCosts.monthlyAmount = data.vehicleMonthly
              ? parseInt(data.vehicleMonthly)
              : null;
          }

          if (
            `${data.educationAmount}` !==
            `${currentEducationCosts.amount || ""}`
          ) {
            income.educationCosts.amount = data.educationAmount
              ? parseInt(data.educationAmount)
              : null;
          }
          if (
            `${data.educationFrequency ? data.educationFrequency.id : null}` !==
            `${currentEducationCosts.frequency}`
          ) {
            income.educationCosts.frequency = data.educationFrequency
              ? (data.educationFrequency.id as PayFrequency)
              : null;
          }
          if (
            `${data.educationMonthly}` !==
            `${currentEducationCosts.monthlyAmount || ""}`
          ) {
            income.educationCosts.monthlyAmount = data.educationMonthly
              ? parseInt(data.educationMonthly)
              : null;
          }

          if (
            `${data.generalExpensesAmount}` !==
            `${currentGeneralExpenses.amount || ""}`
          ) {
            income.generalExpenses.amount = data.generalExpensesAmount
              ? parseInt(data.generalExpensesAmount)
              : null;
          }
          if (
            `${
              data.generalExpensesFrequency
                ? data.generalExpensesFrequency.id
                : null
            }` !== `${currentGeneralExpenses.frequency}`
          ) {
            income.generalExpenses.frequency = data.generalExpensesFrequency
              ? (data.generalExpensesFrequency.id as PayFrequency)
              : null;
          }

          if (
            `${data.generalExpensesMonthly}` !==
            `${currentGeneralExpenses.monthlyAmount || ""}`
          ) {
            income.generalExpenses.monthlyAmount = data.generalExpensesMonthly
              ? parseInt(data.generalExpensesMonthly)
              : null;
          }

          if (
            `${data.otherExpensesAmount}` !==
            `${currentOtherExpenses.amount || ""}`
          ) {
            income.otherExpenses.amount = data.otherExpensesAmount
              ? parseInt(data.otherExpensesAmount)
              : null;
          }
          if (
            `${
              data.otherExpensesFrequency
                ? data.otherExpensesFrequency.id
                : null
            }` !== `${currentOtherExpenses.frequency}`
          ) {
            income.otherExpenses.frequency = data.otherExpensesFrequency
              ? (data.otherExpensesFrequency.id as PayFrequency)
              : null;
          }
          if (
            `${data.otherExpensesMonthly}` !==
            `${currentOtherExpenses.monthlyAmount || ""}`
          ) {
            income.otherExpenses.monthlyAmount = data.otherExpensesMonthly
              ? parseInt(data.otherExpensesMonthly)
              : null;
          }

          if (data.spouseIncludeIncome !== currentSpouse.include) {
            income.spouse.include = data.spouseIncludeIncome;
          }

          if (
            `${data.spouseAnnualIncome}` !==
            `${currentSpouse.annualSalary || ""}`
          ) {
            income.spouse.annualSalary = data.spouseAnnualIncome
              ? parseInt(data.spouseAnnualIncome)
              : null;
          }
          if (
            `${data.spouseSalaryPerPay}` !==
            `${currentSpouse.salaryPerPay || ""}`
          ) {
            income.spouse.salaryPerPay = data.spouseSalaryPerPay
              ? parseInt(data.spouseSalaryPerPay)
              : null;
          }
          if (
            `${data.spousePayFrequency ? data.spousePayFrequency.id : null}` !==
            `${currentSpouse.payFrequency}`
          ) {
            income.spouse.payFrequency = data.spousePayFrequency
              ? (data.spousePayFrequency.id as PayFrequency)
              : null;
          }
          if (
            `${data.spouseNetMonthlyPay}` !==
            `${currentSpouse.netPayPerMonth || ""}`
          ) {
            income.spouse.netPayPerMonth = data.spouseNetMonthlyPay
              ? parseInt(data.spouseNetMonthlyPay)
              : null;
          }
          if (
            `${
              data.spouseEmploymentType ? data.spouseEmploymentType.id : null
            }` !== `${currentSpouse.employmentType}`
          ) {
            income.spouse.employmentType = data.spouseEmploymentType
              ? (data.spouseEmploymentType.id as EmploymentType)
              : null;
          }

          if (
            data.spouseLastTaxReturnImage &&
            data.spouseLastTaxReturnImage.lastModified !==
              data.initSpouseLastTaxReturnImage?.lastModified
          ) {
            uploads.spouseLastTaxReturnImage = data.spouseLastTaxReturnImage;
          }

          if (
            data.spousePrevTaxReturnImage &&
            data.spousePrevTaxReturnImage.lastModified !==
              data.initSpousePrevTaxReturnImage?.lastModified
          ) {
            uploads.spousePrevTaxReturnImage = data.spousePrevTaxReturnImage;
          }

          if (
            data.spouseLatestPayslipImage?.lastModified !==
            data.initSpouseLatestPayslipImage?.lastModified
          ) {
            uploads.spouseLatestPayslipImage =
              data.spouseLatestPayslipImage || undefined;
          }

          if (
            data.spouseIncomeDeclarationImage?.lastModified !==
            data.initSpouseIncomeDeclarationImage?.lastModified
          ) {
            uploads.spouseIncomeDeclarationImage =
              data.spouseIncomeDeclarationImage || undefined;
          }

          if (
            income.investmentIncome !== undefined ||
            income.govtBenefitIncome !== undefined ||
            income.otherIncome !== undefined ||
            Object.keys(income.rent).length > 0 ||
            Object.keys(income.vehicleCosts).length > 0 ||
            Object.keys(income.educationCosts).length > 0 ||
            Object.keys(income.generalExpenses).length > 0 ||
            Object.keys(income.otherExpenses).length > 0 ||
            Object.keys(income.spouse).length > 0
          ) {
            updatePayload.income = {};

            if (income.investmentIncome !== undefined) {
              updatePayload.income.investmentIncome = income.investmentIncome;
            }
            if (income.govtBenefitIncome !== undefined) {
              updatePayload.income.govtBenefitIncome = income.govtBenefitIncome;
            }
            if (income.otherIncome !== undefined) {
              updatePayload.income.otherIncome = income.otherIncome;
            }
            if (Object.keys(income.rent).length > 0) {
              updatePayload.income.rent = income.rent;
            }
            if (Object.keys(income.vehicleCosts).length > 0) {
              updatePayload.income.vehicleCosts = income.vehicleCosts;
            }
            if (Object.keys(income.educationCosts).length > 0) {
              updatePayload.income.educationCosts = income.educationCosts;
            }
            if (Object.keys(income.generalExpenses).length > 0) {
              updatePayload.income.generalExpenses = income.generalExpenses;
            }
            if (Object.keys(income.otherExpenses).length > 0) {
              updatePayload.income.otherExpenses = income.otherExpenses;
            }
            if (Object.keys(income.spouse).length > 0) {
              updatePayload.income.spouse = income.spouse;
            }
          }
          break;
        case "assetsAndLiabilities": {
          // Calculate investment property income and mortgage expenses
          let monthlyPropertyIncome = 0;
          let monthlyMortgageExpense = 0;

          // Add assets and liabilities
          if (data.newAssetsAndLiabilities?.length > 0) {
            const displayOrderMap: Record<number, number> = {};
            currentAssetsAndLiabilities.forEach((item) => {
              if (item.typeId) {
                const currentMaxOrder = displayOrderMap[item.typeId] || 0;

                if (item.displayOrder && item.displayOrder > currentMaxOrder) {
                  displayOrderMap[item.typeId] = item.displayOrder;
                }
              }
            });

            assetsAndLiabilities.add = [];

            data.newAssetsAndLiabilities.forEach((item) => {
              if (!item.toBeRemoved) {
                const {
                  description,
                  type,
                  assetValue,
                  liabilityValue,
                  incomeMonthly,
                  mortgageMonthly,
                  financier,
                } = item;
                const parsedTypeId = type ? parseInt(type.id) : null;
                const currentMaxDisplayOrder = parsedTypeId
                  ? displayOrderMap[parsedTypeId]
                  : null;

                let displayOrder: number;

                if (currentMaxDisplayOrder && currentMaxDisplayOrder >= 1000) {
                  displayOrder = currentMaxDisplayOrder + 1;
                } else {
                  displayOrder = 1000;
                }

                if (parsedTypeId) {
                  displayOrderMap[parsedTypeId] = displayOrder;
                }

                // If type is Property then add to monthly income and mortgage
                if (parsedTypeId === ASSET_AND_LIABILITY_ID_PROPERTY) {
                  monthlyPropertyIncome += incomeMonthly
                    ? parseInt(incomeMonthly)
                    : 0;
                  monthlyMortgageExpense += mortgageMonthly
                    ? parseInt(mortgageMonthly)
                    : 0;
                }

                assetsAndLiabilities.add &&
                  assetsAndLiabilities.add.push({
                    description: description || null,
                    typeId: parsedTypeId,
                    assetValue: assetValue ? parseInt(assetValue) : null,
                    liabilityValue: liabilityValue
                      ? parseInt(liabilityValue)
                      : null,
                    financier: financier ? financier.trim() : null,
                    incomePerMth: incomeMonthly
                      ? parseInt(incomeMonthly)
                      : null,
                    expensePerMth: mortgageMonthly
                      ? parseInt(mortgageMonthly)
                      : null,
                    displayOrder,
                  });
              }
            });
          }

          if (currentAssetsAndLiabilities.length > 0) {
            const existingAssetsAndLiabilityMap: Record<
              string,
              ExistingAssetAndLiability
            > = {};

            if (data.assetsAndLiabilities) {
              data.assetsAndLiabilities.forEach((item) => {
                if (!item.toBeRemoved) {
                  existingAssetsAndLiabilityMap[item.id] = item;
                }
              });
            }

            currentAssetsAndLiabilities.forEach((item) => {
              const updatedItem = existingAssetsAndLiabilityMap[item.id];

              if (updatedItem) {
                const itemUpdate: Partial<
                  Omit<AssetAndLiability, "financeId" | "name" | "typeLabel">
                > = {};

                if (`${updatedItem.description}` !== (item.description || "")) {
                  itemUpdate.description = updatedItem.description;
                }

                if (
                  `${updatedItem.type ? updatedItem.type.id : null}` !==
                  `${item.typeId}`
                ) {
                  itemUpdate.typeId = updatedItem.type
                    ? parseInt(updatedItem.type.id)
                    : null;
                }

                if (
                  `${updatedItem.assetValue}` !== `${item.assetValue || ""}`
                ) {
                  itemUpdate.assetValue = updatedItem.assetValue
                    ? parseInt(updatedItem.assetValue)
                    : null;
                }

                if (
                  `${updatedItem.liabilityValue}` !==
                  `${item.liabilityValue || ""}`
                ) {
                  itemUpdate.liabilityValue = updatedItem.liabilityValue
                    ? parseInt(updatedItem.liabilityValue)
                    : null;
                }

                if (`${updatedItem.financier}` !== (item.financier || "")) {
                  itemUpdate.financier = updatedItem.financier.trim();
                }

                if (
                  `${updatedItem.incomeMonthly}` !==
                  `${item.incomePerMth || ""}`
                ) {
                  itemUpdate.incomePerMth = updatedItem.incomeMonthly
                    ? parseInt(updatedItem.incomeMonthly)
                    : null;
                }

                if (
                  `${updatedItem.mortgageMonthly}` !==
                  `${item.expensePerMth || ""}`
                ) {
                  itemUpdate.expensePerMth = updatedItem.mortgageMonthly
                    ? parseInt(updatedItem.mortgageMonthly)
                    : null;
                }

                const parsedTypeId = updatedItem.type
                  ? parseInt(updatedItem.type.id)
                  : null;

                // If type is Property then add to monthly income and mortgage
                if (parsedTypeId === ASSET_AND_LIABILITY_ID_PROPERTY) {
                  monthlyPropertyIncome += updatedItem.incomeMonthly
                    ? parseInt(updatedItem.incomeMonthly)
                    : 0;
                  monthlyMortgageExpense += updatedItem.mortgageMonthly
                    ? parseInt(updatedItem.mortgageMonthly)
                    : 0;
                }

                // Update asset and liability if it has changed
                if (Object.keys(itemUpdate).length > 0) {
                  if (!assetsAndLiabilities.update) {
                    assetsAndLiabilities.update = [];
                  }

                  assetsAndLiabilities.update.push({
                    ...itemUpdate,
                    id: updatedItem.id,
                  });
                }
              } else {
                // Remove asset and liability
                if (!assetsAndLiabilities.remove) {
                  assetsAndLiabilities.remove = [];
                }
                assetsAndLiabilities.remove.push(item.id);
              }
            });
          }

          if (Object.keys(assetsAndLiabilities).length > 0) {
            updatePayload.assetsAndLiabilities = assetsAndLiabilities;

            // Add property income and mortgage
            // let monthlyPropertyIncome = 0;
            // let monthlyMortgageExpense = 0;
            const annualPropertyIncome = monthlyPropertyIncome * 12;
            const propertyIncomeHasChanged =
              `${annualPropertyIncome}` !== `${investmentPropertyIncome || ""}`;
            const mortgageExpenseHasChanged =
              `${monthlyMortgageExpense}` !== `${currentMortgage.amount || ""}`;

            if (propertyIncomeHasChanged || mortgageExpenseHasChanged) {
              if (!updatePayload.income) {
                updatePayload.income = {};
              }

              if (propertyIncomeHasChanged) {
                updatePayload.income.investmentPropertyIncome =
                  annualPropertyIncome;
              }

              if (mortgageExpenseHasChanged) {
                updatePayload.income.mortgage = {
                  amount: monthlyMortgageExpense,
                  frequency: "monthly",
                  monthlyAmount: monthlyMortgageExpense,
                };
              }
            }

            if (
              `${annualPropertyIncome}` !== `${investmentPropertyIncome || ""}`
            ) {
              income.investmentPropertyIncome = annualPropertyIncome;
            }
          }

          // Credit Cards
          if (`${data.creditCardProviders}` !== (creditCardProviders || "")) {
            creditCards.creditCardProviders = data.creditCardProviders;
          }

          if (
            `${data.totalCreditCardLimit}` !== `${totalCreditCardLimit || ""}`
          ) {
            creditCards.totalCreditCardLimit = data.totalCreditCardLimit
              ? parseInt(data.totalCreditCardLimit)
              : null;
          }

          if (
            `${data.totalCreditCardBalance}` !==
            `${totalCreditCardBalance || ""}`
          ) {
            creditCards.totalCreditCardBalance = data.totalCreditCardBalance
              ? parseInt(data.totalCreditCardBalance)
              : null;
          }

          if (Object.keys(creditCards).length > 0) {
            updatePayload.creditCards = creditCards;
          }

          break;
        }
      }

      if (Object.keys(uploads).length > 0) {
        updatePayload.fileUploads = uploads;
      }

      if (Object.keys(updatePayload).length > 0) {
        await mutateAsync(
          updatePayload,

          {
            onSuccess: (data, payload) => {
              if (data) {
                queryClient.setQueryData(
                  ["finance-application", dossierId],
                  data
                );

                // Set property income and mortgage values
                if (
                  data.income.investmentPropertyIncome ||
                  data.income.investmentPropertyIncome === 0
                ) {
                  setValue(
                    "investmentPropertyIncome",
                    `${data.income.investmentPropertyIncome}`
                  );
                }

                if (data.income.mortgage) {
                  setValue(
                    "mortgageAmount",
                    `${data.income.mortgage.amount || 0}`
                  );

                  if (data.income.mortgage.frequency) {
                    setValue("mortgageFrequency", {
                      id: data.income.mortgage.frequency,
                      label: getAutoCompleteLabel(
                        payFrequencyOptions,
                        data.income.mortgage.frequency
                      ),
                    });
                  }
                }
              }

              // Update file upload initial states
              if (payload.fileUploads) {
                const setInitImage = (
                  image: File | undefined,
                  initImageName: keyof FinanceApplicationInputs
                ) => {
                  if (image) {
                    setValue(initImageName, image);
                  }
                };

                const {
                  driversLicenseFrontImage,
                  driversLicenseBackImage,
                  lastTaxReturnImage,
                  prevTaxReturnImage,
                  latestPayslipImage,
                  prevPayslipImage,
                  spouseLastTaxReturnImage,
                  spousePrevTaxReturnImage,
                  spouseLatestPayslipImage,
                  spouseIncomeDeclarationImage,
                } = payload.fileUploads;
                setInitImage(
                  driversLicenseFrontImage,
                  "initDriversLicenseFrontImage"
                );
                setInitImage(
                  driversLicenseBackImage,
                  "initDriversLicenseBackImage"
                );
                setInitImage(lastTaxReturnImage, "initLastTaxReturnImage");
                setInitImage(prevTaxReturnImage, "initPrevTaxReturnImage");
                setInitImage(latestPayslipImage, "initLatestPayslipImage");
                setInitImage(prevPayslipImage, "initPrevPayslipImage");
                setInitImage(
                  spouseLastTaxReturnImage,
                  "initSpouseLastTaxReturnImage"
                );
                setInitImage(
                  spousePrevTaxReturnImage,
                  "initSpousePrevTaxReturnImage"
                );
                setInitImage(
                  spouseLatestPayslipImage,
                  "initSpouseLatestPayslipImage"
                );
                setInitImage(
                  spouseIncomeDeclarationImage,
                  "initSpouseIncomeDeclarationImage"
                );
              }
            },
          }
        );
      }
    }
  };

  const onSave = async (data: FinanceApplicationInputs) => {
    await onSubmit(data);
  };

  const onNext = async (data: FinanceApplicationInputs) => {
    await onSubmit(data);
    navigateToStep(currentStepIndex + 1);
  };

  const onBack = async (data: FinanceApplicationInputs) => {
    await onSubmit(data);
    navigateToStep(currentStepIndex - 1);
  };

  const onFinalSubmit = async (data: FinanceApplicationInputs) => {
    if (isLocked) {
      navigate(getScopedPageUrl("viewDossier", dossierId));
    } else {
      const missingFields = getMissingRequiredFields();
      const hasMissingFields = Object.values(missingFields).some((step) => {
        return step.length > 0;
      });

      if (hasMissingFields) {
        await onSubmit(data, "submit-attempted");
        setShowSubmissionModal(true);
      } else {
        await onSubmit(data, "entered");
        navigate(
          getScopedPageUrl("dossierActionConfirmation", dossierId, "100000001")
        );
      }
    }
  };

  const onCloseSubmissionModal = () => {
    setShowSubmissionModal(false);
  };

  const onCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  const renderMissingRequiredFields = () => {
    const missingFields = getMissingRequiredFields();
    const renderedMissingStepFields: ReactNode[] = [];
    steps.forEach((step, index) => {
      const missingStepFields = missingFields[step.id];

      if (missingStepFields && missingStepFields.length > 0) {
        renderedMissingStepFields.push(
          <Box sx={{ color: "primary.main" }} key={index}>
            <Link
              to={`${getScopedPageUrl(
                "dossierFinanceApplication",
                dossierId
              )}?step=${index}`}
              onClick={() => setShowSubmissionModal(false)}
            >
              {step.title}
            </Link>
          </Box>
        );
      }
    });

    return (
      <Stack sx={{ pt: 2 }} spacing={1}>
        {renderedMissingStepFields}
      </Stack>
    );
  };

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;

  return (
    <PageContainer title={currentStep?.title || ""} loading={isLoading}>
      <Modal open={showSubmissionModal} onClose={onCloseSubmissionModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper
            sx={{ p: 2, maxWidth: "600px", overflowY: "auto", width: "80vw" }}
          >
            <Box>
              <Typography variant="titleMedium">
                Please complete all required fields in the Finance Application
                to proceed. The following pages are missing required
                information:
              </Typography>
              {showSubmissionModal && renderMissingRequiredFields()}
            </Box>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={onCloseSubmissionModal}
              >
                Close
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
      <Modal open={showTermsModal} onClose={onCloseTermsModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Paper
            sx={{
              py: 2,
              px: 4,
              overflowY: "auto",
              maxWidth: "900px",
              maxHeight: "95vh",
              width: "95vw",
            }}
          >
            <Terms termsId="finance" />
            <Box sx={{ mt: 3, display: "flex", justifyContent: "end" }}>
              <Button
                size="large"
                color="primary"
                variant="outlined"
                disableElevation={true}
                onClick={onCloseTermsModal}
              >
                Close
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
      {isSuccess && (
        <Stack spacing={2}>
          {currentStep?.description && (
            <Typography variant="labelLarge">
              {currentStep.description}
            </Typography>
          )}
          <APForm
            onSubmit={
              isLastStep ? handleSubmit(onFinalSubmit) : handleSubmit(onNext)
            }
            submitText={isLastStep && !isLocked ? "Submit" : "Next"}
            submitIcon={<PlayArrowOutlined />}
            isLoading={isUpdating}
            leftButton={
              <BackButton
                onClick={isFirstStep ? undefined : handleSubmit(onBack)}
              />
            }
            additionalButton={
              <Button
                size="large"
                color="primary"
                variant="contained"
                disableElevation={true}
                onClick={handleSubmit(onSave)}
                disabled={isLocked}
                ref={saveButtonRef}
              >
                Save
              </Button>
            }
            isError={Object.keys(errors).length > 0}
          >
            <Box>{steps.map(renderFormStep)}</Box>
          </APForm>
        </Stack>
      )}
    </PageContainer>
  );
};

export default FinanceApplication;
